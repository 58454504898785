var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Slide } from 'react-awesome-reveal';
export var Title = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 1rem;\n  line-height: 1.5rem;\n  letter-spacing: 0.7px;\n  text-align: left;\n"], ["\n  font-size: 1rem;\n  line-height: 1.5rem;\n  letter-spacing: 0.7px;\n  text-align: left;\n"])));
export var ButtonSection = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: auto;\n  text-align: center;\n"], ["\n  margin-top: auto;\n  text-align: center;\n"])));
export var ContentArea = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0 20px;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n"], ["\n  padding: 0 20px;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n"])));
export var SlideWrapper = styled(Slide)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n"], ["\n  flex: 1;\n  display: flex;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
