var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from '@bp/ui-components/web';
import { ThemeProvider as WebProvider } from 'styled-components';
import GlobalStyles from './utils/GlobalStyles';
import { theme as bpCore } from '@bp/ui-components/web/core';
import { theme as bpPulseOverride } from '@bp/ui-components/web/core/themes/pulse';
import { useUrlParams } from './providers/UrlParamProvider';
import defaultsDeep from 'lodash.defaultsdeep';
import micrositeTheme from './theme/theme';
var StyleWrapper = function (_a) {
    var children = _a.children;
    var contextTheme = useUrlParams().theme;
    var siteTheme = contextTheme === 'aral'
        ? defaultsDeep(micrositeTheme, bpCore)
        : defaultsDeep(micrositeTheme, bpPulseOverride, bpCore);
    return (_jsx(ThemeProvider, __assign({ customTheme: siteTheme }, { children: _jsxs(WebProvider, __assign({ theme: siteTheme }, { children: [_jsx(GlobalStyles, {}), _jsx(_Fragment, { children: children })] })) })));
};
export default StyleWrapper;
