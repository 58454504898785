import { useTranslation } from 'react-i18next';
var useValidation = function () {
    var t = useTranslation().t;
    var errorMessages = {
        full_name: "".concat(t('errors.generic'), " ").concat(t('specialChars.alpha'), " -`'~ ").concat(t('usualWords.and'), " ").concat(t('specialChars.spaces')).concat(' ' + t('errors.only')),
        country: "".concat(t('errors.generic'), " ").concat(t('specialChars.alpha'), ", & ' . ").concat(t('usualWords.and'), " ").concat(t('specialChars.spaces')).concat(' ' + t('errors.only')),
        town: "".concat(t('errors.generic'), " ").concat(t('specialChars.alpha'), " ").concat(t('usualWords.and'), " ").concat(t('specialChars.spaces')).concat(' ' + t('errors.only')),
        city: "".concat(t('errors.generic'), " ").concat(t('specialChars.alpha'), "& - ' ").concat(t('usualWords.and'), " ").concat(t('specialChars.spaces')).concat(' ' + t('errors.only')),
        postcode: t('errors.generic'),
        zipcode: t('errors.generic'),
        house_number: "".concat(t('errors.generic'), " ").concat(t('specialChars.alphanumerics'), ", - ").concat(t('usualWords.and'), " /").concat(' ' + t('errors.only')),
        generic: t('errors.generic'),
        street_address: "".concat(t('errors.generic'), " ").concat(t('specialChars.alphanumerics'), ", . ' - ").concat(t('specialChars.spaces')).concat(' ' + t('errors.only')),
        required: t('errors.requiredField'),
        phone_number: "".concat(t('errors.generic')),
        email: "".concat(t('errors.generic'), " ").concat(t('errors.email')),
        spreedly_number: t('errors.spreedlyNumber'),
        spreedly_number_accepted: t('errors.spreedlyNumberAccepted'),
        spreedly_cvv: t('errors.spreedlyCvv'),
    };
    var patterns = {
        full_name: /^[~.a-zA-Z'‘’\-\s]*$/,
        expire_month: /^[0-9]{2}$/,
        expire_year: /^[0-9]{2}$/,
        country: /^[\s()'.&‘’\p{L}-]*$/u,
        house_number: /^[.a-zA-Z-/\s0-9]*$/,
        street_address: /^[.,'‘’\s\-0-9\p{L}-]*$/u,
        postcode: /^[a-zA-Z\-\s0-9]*$/,
        zipcode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
        town: /^['&\-\s\p{L}-]*$/u,
        city: /^['&\-\s\p{L}-]*$/u,
        phone_number: /(?:[0-9]){6,14}[0-9]$/,
        email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    };
    var maxLengths = {
        default: 100,
        phone_number: 15,
        expire_month: 2,
        expire_year: 2,
        zipcode: 5,
    };
    return { errorMessages: errorMessages, patterns: patterns, maxLengths: maxLengths };
};
export default useValidation;
