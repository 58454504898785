var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './TermsAndConditions.styles';
import { chooseTermsConditionsURL, choosePrivacyPolicyURL, } from '../../../utils/chooseTermsConditionsURL';
import Iframe from '../Iframe/Iframe';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../../../assets/loading.svg';
var TermsAndConditions = function () {
    var t = useTranslation().t;
    var search = useLocation().search;
    var _a = useState(), iFrameState = _a[0], setIFrameState = _a[1];
    var country = new URLSearchParams(search).get('country');
    var tcppFlag = process.env.REACT_APP_TCPP;
    var tcpp = (tcppFlag === null || tcppFlag === void 0 ? void 0 : tcppFlag.toLowerCase()) === 'true';
    useEffect(function () {
        // preload loading spinner;
        var img = new Image();
        img.src = LoadingSpinner;
    });
    var onPressTermsAndConditionsHandler = function () {
        setIFrameState('TERMS_AND_CONDITIONS');
    };
    var onPressPrivacyPolicyHandler = function () {
        setIFrameState('PRIVACY_POLICY');
    };
    var onPressCloseModalHandler = function () {
        setIFrameState(undefined);
    };
    return (_jsx(_Fragment, { children: tcpp ? (_jsx(_Fragment, { children: _jsx(S.Text, __assign({ id: "linkTermsAndConditions" }, { children: t('terms.termsConditionsAlt') })) })) : (_jsxs(_Fragment, { children: [_jsxs(S.Text, __assign({ id: "linkTermsAndConditions" }, { children: [t('terms.termsConditions1'), _jsx(S.Link, __assign({ onClick: onPressTermsAndConditionsHandler, role: "link", "aria-label": t('terms.termsConditions2') }, { children: ' ' + t('terms.termsConditions2') + ' ' })), t('usualWords.and') + ' ' + t('usualWords.the'), _jsx(S.Link, __assign({ onClick: onPressPrivacyPolicyHandler, role: "link", "aria-label": t('terms.termsConditions3') }, { children: ' ' + t('terms.termsConditions3') + ' ' })), t('terms.termsConditions4')] })), iFrameState && (_jsx(Iframe, { initialUrl: iFrameState === 'privacyPolicy'
                        ? choosePrivacyPolicyURL(country)
                        : chooseTermsConditionsURL(country), titleKey: iFrameState, backHandler: onPressCloseModalHandler, sandbox: 'sandbox' }))] })) }));
};
export default TermsAndConditions;
