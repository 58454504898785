var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Title = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: center;\n  font-size: 1.5rem;\n  line-height: 2rem;\n  margin: 0;\n"], ["\n  text-align: center;\n  font-size: 1.5rem;\n  line-height: 2rem;\n  margin: 0;\n"])));
export var ContentArea = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 20px;\n  flex-grow: 1;\n  flex-direction: column;\n"], ["\n  padding: 0 20px;\n  flex-grow: 1;\n  flex-direction: column;\n"])));
export var ContentMiddleArea = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  border-left: none;\n  border-right: none;\n  background-color: ", ";\n  margin-top: auto;\n  display: flex;\n  flex-direction: column;\n  padding: 16px 20px;\n"], ["\n  border: 1px solid ", ";\n  border-left: none;\n  border-right: none;\n  background-color: ", ";\n  margin-top: auto;\n  display: flex;\n  flex-direction: column;\n  padding: 16px 20px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.appBackground.primary;
});
export var Heading = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0;\n  font-size: 1rem;\n  line-height: 1.5;\n"], ["\n  margin: 0;\n  font-size: 1rem;\n  line-height: 1.5;\n"])));
export var SmallText = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 0.75rem;\n  line-height: 1.5;\n  margin: 0;\n  letter-spacing: 0.6px;\n  opacity: 0.7;\n"], ["\n  font-size: 0.75rem;\n  line-height: 1.5;\n  margin: 0;\n  letter-spacing: 0.6px;\n  opacity: 0.7;\n"])));
export var FormRow = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-block;\n  width: 90%;\n"], ["\n  display: inline-block;\n  width: 90%;\n"])));
export var Bottom = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-top: auto;\n  text-align: center;\n\n  /* background-color: red; */\n  /* bottom: 0; */\n  /* position: relative; */\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-top: auto;\n  text-align: center;\n\n  /* background-color: red; */\n  /* bottom: 0; */\n  /* position: relative; */\n"])));
export var FormLabel = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-bottom: 0.2rem;\n  font-size: 0.875rem;\n"], ["\n  margin-bottom: 0.2rem;\n  font-size: 0.875rem;\n"])));
export var FormSubLabel = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 0.6875rem;\n  display: inline-block;\n  margin-left: 5px;\n  opacity: 0.8;\n"], ["\n  font-size: 0.6875rem;\n  display: inline-block;\n  margin-left: 5px;\n  opacity: 0.8;\n"])));
export var CardNumberColumn = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 70%;\n  float: left;\n"], ["\n  width: 70%;\n  float: left;\n"])));
export var CVVColumn = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 15%;\n  float: right;\n"], ["\n  width: 15%;\n  float: right;\n"])));
export var FormSPField = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-right: 1rem;\n"], ["\n  margin-right: 1rem;\n"])));
export var FormInput = styled.input(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  border-radius: 0;\n  font-family: inherit;\n  border: 1px solid ", ";\n  appearance: none;\n  box-shadow: none;\n  margin: 0;\n  padding-left: 0 1rem;\n  outline: none;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  letter-spacing: 0.7px;\n  text-align: left;\n  width: 100%;\n  height: 30px;\n"], ["\n  border-radius: 0;\n  font-family: inherit;\n  border: 1px solid ", ";\n  appearance: none;\n  box-shadow: none;\n  margin: 0;\n  padding-left: 0 1rem;\n  outline: none;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  letter-spacing: 0.7px;\n  text-align: left;\n  width: 100%;\n  height: 30px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral[400];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
