var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n  background-color: rgba(0, 0, 0, 0.5);\n"], ["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n  background-color: rgba(0, 0, 0, 0.5);\n"])));
export var Modal = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 0.75rem;\n  flex-grow: 1;\n  padding-top: 1rem;\n"], ["\n  background-color: ", ";\n  border-radius: 0.75rem;\n  flex-grow: 1;\n  padding-top: 1rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.appBackground.primary;
});
export var Heading = styled.h4(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: bold;\n  text-align: center;\n  font-size: 1.125rem;\n  letter-spacing: 0.7px;\n  font-family: 'Aral POS';\n  margin: 0.5rem 0;\n  padding: 0 2.5rem;\n"], ["\n  color: ", ";\n  font-weight: bold;\n  text-align: center;\n  font-size: 1.125rem;\n  letter-spacing: 0.7px;\n  font-family: 'Aral POS';\n  margin: 0.5rem 0;\n  padding: 0 2.5rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.black;
});
export var Content = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  text-align: center;\n  font-size: 0.875rem;\n  padding: 0 2.5rem;\n  line-height: 1.5;\n  opacity: 0.8;\n"], ["\n  color: ", ";\n  text-align: center;\n  font-size: 0.875rem;\n  padding: 0 2.5rem;\n  line-height: 1.5;\n  opacity: 0.8;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.text;
});
export var Button = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: block;\n  border-top: 1px solid ", ";\n  opacity: 0.8;\n  text-align: center;\n  overflow: hidden;\n  padding: 1rem;\n  font-size: 0.875rem;\n"], ["\n  display: block;\n  border-top: 1px solid ", ";\n  opacity: 0.8;\n  text-align: center;\n  overflow: hidden;\n  padding: 1rem;\n  font-size: 0.875rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral[400];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
