import { LanguageLocale, SupportedCountries } from '../common/enums';
var returnURLOrPlaceholder = function (url) { return url !== null && url !== void 0 ? url : 'https://www.google.com'; };
export var chooseTermsConditionsURL = function (country) {
    var _a = process.env, termsConditionsDE = _a.REACT_APP_TERMS_CONDITIONS_URL_DE, termsConditionsNL = _a.REACT_APP_TERMS_CONDITIONS_URL_NL, termsConditionsES = _a.REACT_APP_TERMS_CONDITIONS_URL_ES;
    switch (country) {
        case SupportedCountries.GERMANY:
        case LanguageLocale.GERMAN:
            return returnURLOrPlaceholder(termsConditionsDE);
        case SupportedCountries.SPAIN:
        case LanguageLocale.SPAIN:
            return returnURLOrPlaceholder(termsConditionsES);
        default:
            return returnURLOrPlaceholder(termsConditionsNL);
    }
};
export var choosePrivacyPolicyURL = function (country) {
    var _a = process.env, privacyPolicyDE = _a.REACT_APP_PRIVACY_POLICY_URL_DE, privacyPolicyNL = _a.REACT_APP_PRIVACY_POLICY_URL_NL, privacyPolicyES = _a.REACT_APP_PRIVACY_POLICY_URL_ES;
    switch (country) {
        case SupportedCountries.GERMANY:
        case LanguageLocale.GERMAN:
            return returnURLOrPlaceholder(privacyPolicyDE);
        case SupportedCountries.SPAIN:
        case LanguageLocale.SPAIN:
            return returnURLOrPlaceholder(privacyPolicyES);
        default:
            return returnURLOrPlaceholder(privacyPolicyNL);
    }
};
