var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { createGlobalStyle } from 'styled-components';
import aralRegular from '../fonts/AralPOS/AralPOS-Regular.ttf';
import robotoRegular from '../fonts/Roboto/Roboto-Regular.ttf';
import breakpoints from './breakpoints';
import { isAndroid } from './getPlatform';
var GlobalStyles = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\n@font-face {\n  font-family: 'Aral POS';\n  font-style: normal;\n  font-weight: normal;\n  src: url(", ") format('truetype');\n}\n\n  @font-face {\n    font-family: 'Roboto-Regular';\n    font-style: normal;\n    font-weight: 600;\n    src: url(", ") format('truetype');\n  }\n\n  html, body {\n    font-family: 'Roboto-Regular';\n    font-size: 100%; /* 16px base font */\n    min-width: ", ";\n    overflow-x: hidden;\n    min-height: calc(100vh - ", "px);\n  }\n\n  body {\n    color: #111111;\n    background-color: #fafafa;\n  }\n\n  a {\n    text-decoration: none;\n    color: #2b47e6 !important;\n  }\n\n"], ["\n\n@font-face {\n  font-family: 'Aral POS';\n  font-style: normal;\n  font-weight: normal;\n  src: url(", ") format('truetype');\n}\n\n  @font-face {\n    font-family: 'Roboto-Regular';\n    font-style: normal;\n    font-weight: 600;\n    src: url(", ") format('truetype');\n  }\n\n  html, body {\n    font-family: 'Roboto-Regular';\n    font-size: 100%; /* 16px base font */\n    min-width: ", ";\n    overflow-x: hidden;\n    min-height: calc(100vh - ", "px);\n  }\n\n  body {\n    color: #111111;\n    background-color: #fafafa;\n  }\n\n  a {\n    text-decoration: none;\n    color: #2b47e6 !important;\n  }\n\n"])), aralRegular, robotoRegular, breakpoints.breakpoints.minWidth, isAndroid() ? '48' : '0');
export default GlobalStyles;
var templateObject_1;
