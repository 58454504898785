var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import styledNative from 'styled-components/native';
export var PhonePrefixSelectWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid\n    ", ";\n  background-color: ", ";\n\n  height: 3rem;\n  width: 100%;\n  box-sizing: border-box;\n\n  border-radius: 0;\n  appearance: none;\n  box-shadow: none;\n  outline: none;\n  font-family: inherit;\n  padding: 0 1em;\n\n  font-size: 0.875rem;\n  letter-spacing: 0.7px;\n  text-align: left;\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  border: 1px solid\n    ", ";\n  background-color: ", ";\n\n  height: 3rem;\n  width: 100%;\n  box-sizing: border-box;\n\n  border-radius: 0;\n  appearance: none;\n  box-shadow: none;\n  outline: none;\n  font-family: inherit;\n  padding: 0 1em;\n\n  font-size: 0.875rem;\n  letter-spacing: 0.7px;\n  text-align: left;\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n"])), function (_a) {
    var error = _a.error, theme = _a.theme;
    return (error ? theme.bpCore.colors.error : theme.bpCore.colors.neutral[400]);
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.appBackground.primary;
});
export var ValueWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-grow: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  flex-grow: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var Menu = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0;\n  margin: 0;\n  border: 1px solid\n    ", ";\n  border-top: none;\n  background-color: ", ";\n  width: 100%;\n  height: 350px;\n  overflow-y: scroll;\n  position: absolute;\n  top: calc(100% + 1px);\n  left: -1px;\n  right: -1px;\n  z-index: 1;\n"], ["\n  padding: 0;\n  margin: 0;\n  border: 1px solid\n    ", ";\n  border-top: none;\n  background-color: ", ";\n  width: 100%;\n  height: 350px;\n  overflow-y: scroll;\n  position: absolute;\n  top: calc(100% + 1px);\n  left: -1px;\n  right: -1px;\n  z-index: 1;\n"])), function (_a) {
    var error = _a.error, theme = _a.theme;
    return (error ? theme.bpCore.colors.error : theme.bpCore.colors.neutral[400]);
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.appBackground.primary;
});
export var MenuItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 3rem;\n  width: 100%;\n  background-color: transparent;\n  border: 1px solid ", ";\n  border-left: none;\n  border-right: none;\n  border-top: none;\n  display: flex;\n  justify-content: flex-start;\n  align-items: baseline;\n  padding-left: 3rem;\n  align-items: center;\n  flex-grow: 1;\n"], ["\n  height: 3rem;\n  width: 100%;\n  background-color: transparent;\n  border: 1px solid ", ";\n  border-left: none;\n  border-right: none;\n  border-top: none;\n  display: flex;\n  justify-content: flex-start;\n  align-items: baseline;\n  padding-left: 3rem;\n  align-items: center;\n  flex-grow: 1;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral[400];
});
export var Placeholder = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral[600];
});
export var Flag = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 1rem;\n  width: 1.5rem;\n  object-fit: cover;\n  margin-right: 6px;\n"], ["\n  height: 1rem;\n  width: 1.5rem;\n  object-fit: cover;\n  margin-right: 6px;\n"])));
export var Container = styledNative.View(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: 1px;\n  border-color: rgb(182, 182, 182);\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 108px;\n  height: 50px;\n"], ["\n  border: 1px;\n  border-color: rgb(182, 182, 182);\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 108px;\n  height: 50px;\n"])));
export var ImageContainer = styledNative.View(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-left: 8px;\n"], ["\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-left: 8px;\n"])));
export var DialCode = styledNative.Text(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-left: 3px;\n"], ["\n  margin-left: 3px;\n"])));
export var Backdrop = styledNative.View(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  background-color: rgba(17, 17, 17, 0.58);\n  position: absolute;\n"], ["\n  height: 100%;\n  width: 100%;\n  background-color: rgba(17, 17, 17, 0.58);\n  position: absolute;\n"])));
export var ContentWrapper = styledNative.View(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  flex: 1;\n  width: 100%;\n  margin-top: 65px;\n  overflow: hidden;\n  background-color: #fff;\n  border-radius: 12px;\n"], ["\n  flex: 1;\n  width: 100%;\n  margin-top: 65px;\n  overflow: hidden;\n  background-color: #fff;\n  border-radius: 12px;\n"])));
export var HeaderWrapper = styledNative.View(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background-color: rgb(250, 250, 250);\n  border-top-left-radius: 20px;\n  border-top-right-radius: 20px;\n  border-bottom-width: 1px;\n  border-bottom-color: rgb(222, 222, 222);\n"], ["\n  background-color: rgb(250, 250, 250);\n  border-top-left-radius: 20px;\n  border-top-right-radius: 20px;\n  border-bottom-width: 1px;\n  border-bottom-color: rgb(222, 222, 222);\n"])));
export var HeaderTitle = styledNative.Text(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding-vertical: 24px;\n  padding-horizontal: 32px;\n  font-size: 22px;\n  letter-spacing: 0.4px;\n  line-height: 30px;\n  color: rgb(17, 17, 17);\n  text-align: center;\n"], ["\n  padding-vertical: 24px;\n  padding-horizontal: 32px;\n  font-size: 22px;\n  letter-spacing: 0.4px;\n  line-height: 30px;\n  color: rgb(17, 17, 17);\n  text-align: center;\n"])));
export var Country = styledNative.TouchableOpacity(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  padding-vertical: 28px;\n  background-color: rgb(250, 250, 250);\n  padding-left: 24px;\n  border-color: rgb(222, 222, 222);\n  border-top-width: ", ";\n  border-bottom-width: 1px;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  position: relative;\n"], ["\n  padding-vertical: 28px;\n  background-color: rgb(250, 250, 250);\n  padding-left: 24px;\n  border-color: rgb(222, 222, 222);\n  border-top-width: ", ";\n  border-bottom-width: 1px;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  position: relative;\n"])), function (_a) {
    var isFirstChild = _a.isFirstChild;
    return (!isFirstChild ? '1px' : '0px');
});
export var Name = styledNative.Text(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin-left: 16px;\n  font-size: 16px;\n  letter-spacing: 0.7px;\n  line-height: 16px;\n  color: rgb(17, 17, 17);\n  flex: 1;\n  position: relative;\n"], ["\n  margin-left: 16px;\n  font-size: 16px;\n  letter-spacing: 0.7px;\n  line-height: 16px;\n  color: rgb(17, 17, 17);\n  flex: 1;\n  position: relative;\n"])));
export var RadioButton = styled.img(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  position: relative;\n  margin-right: 24px;\n  z-index: 1;\n"], ["\n  width: 24px;\n  height: 24px;\n  position: relative;\n  margin-right: 24px;\n  z-index: 1;\n"])));
export var CountryButton = styledNative.TouchableOpacity(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
export var ConfirmButtonContainer = styledNative.View(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  width: 100%;\n  shadow-color: rgb(0, 0, 0);\n  shadow-opacity: 0.2;\n  shadow-radius: 20px;\n  elevation: 30;\n  background-color: white;\n  padding-vertical: 16px;\n  padding-horizontal: 24px;\n"], ["\n  width: 100%;\n  shadow-color: rgb(0, 0, 0);\n  shadow-opacity: 0.2;\n  shadow-radius: 20px;\n  elevation: 30;\n  background-color: white;\n  padding-vertical: 16px;\n  padding-horizontal: 24px;\n"])));
export var InputText = styledNative.TextInput(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  width: 100%;\n  background-color: rgb(250, 250, 250);\n  height: 40px;\n  padding: 10px;\n"], ["\n  width: 100%;\n  background-color: rgb(250, 250, 250);\n  height: 40px;\n  padding: 10px;\n"])));
export var InputWrapper = styledNative.View(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  width: 100%;\n  margin: auto;\n  padding: 12px 24px;\n"], ["\n  width: 100%;\n  margin: auto;\n  padding: 12px 24px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
