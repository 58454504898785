var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { isAndroid } from '../../utils/getPlatform';
import { useHistory } from 'react-router-dom';
/* eslint-disable no-console */
var lastRequestID = 0;
var clientUUID = Math.round(Math.random() * 10000);
var serviceOutagePath = "/service-outage".concat(window.location.search);
var authErrorCodes = [401, 403];
var localApolloUrl = isAndroid()
    ? 'http://10.0.2.2:4030/graphql'
    : 'http://localhost:4030/graphql';
var errorLink = onError(function (_a) {
    var _b, _c;
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation;
    var errorCode = (_b = graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors[0]) === null || _b === void 0 ? void 0 : _b.code;
    var networkErrorCode = (_c = networkError === null || networkError === void 0 ? void 0 : networkError.response) === null || _c === void 0 ? void 0 : _c.status;
    // TODO: add logger for local development only
    // if (graphQLErrors) console.log('[GraphQL Error]: ', graphQLErrors);
    // if (networkError) console.log('[Network Error]: ', networkError);
    var search = useHistory().location.search;
    var locale = new URLSearchParams(search).get('locale');
    if (authErrorCodes.includes(errorCode) ||
        authErrorCodes.includes(networkErrorCode) ||
        (!errorCode && !networkErrorCode && operation.operationName === 'getPaymentMethodsWallet'))
        window.location.replace("/not-authorised?&locale=".concat(locale));
    else if (errorCode !== 500)
        window.location.replace(serviceOutagePath);
});
// Apply request tracking middleware
var jaeger = setContext(function (_a) {
    var operationName = _a.operationName, headers = _a.headers;
    // Only increment request count if a operation exists
    if (operationName)
        lastRequestID += 1;
    return {
        headers: __assign(__assign({}, headers), { 'jaeger-baggage': "session=".concat(clientUUID, ", request=").concat(lastRequestID), jaegersession: clientUUID, jaegerrequest: lastRequestID }),
    };
});
var walletLink = createHttpLink({
    uri: process.env.REACT_APP_APOLLO_WALLET_SERVER || localApolloUrl,
});
// create an inmemory cache instance for caching graphql data
var cache = new InMemoryCache();
var setAuthLink = function (authToken, apiKey) {
    // Create authentication middleware to add id_token to requests
    return setContext(function (_, _a) {
        var headers = _a.headers;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                return [2 /*return*/, {
                        headers: __assign(__assign({}, headers), { authorization: authToken, 'x-api-key': process.env.REACT_APP_API_GATEWAY_KEY }),
                    }];
            });
        });
    });
};
var WalletClient = function (authToken, apiKey) {
    return new ApolloClient({
        link: from([jaeger, setAuthLink(authToken, apiKey), errorLink, walletLink]),
        cache: cache,
    });
};
export { WalletClient };
