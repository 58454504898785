import { jsx as _jsx } from "react/jsx-runtime";
import BillingUS from './CountrySpecific/BillingUS';
import BillingEU from './CountrySpecific/BillingEU';
import { useUrlParams } from '../../../providers/UrlParamProvider';
var Billing = function () {
    var country = useUrlParams().country;
    switch (country) {
        case 'US':
            return _jsx(BillingUS, {});
        default:
            return _jsx(BillingEU, {});
    }
};
export default Billing;
