var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spacer } from '@bp/ui-components/web';
import TimeoutErrorSvg from '../../../assets/time_out_error.svg';
import * as S from './NotAuthorised.styles';
var NotAuthorised = function () {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var search = useHistory().location.search;
    var locale = new URLSearchParams(search).get('locale');
    var changeLanguage = function (locale) { return i18n.changeLanguage(locale); };
    useEffect(function () {
        changeLanguage(locale);
    }, [i18n, locale]);
    return (_jsxs(_Fragment, { children: [_jsx(S.Title, { children: t('notAuthorised.heading') }), _jsx(Spacer, { vSpace: 16 }), _jsx(S.Image, { alt: "Error image", src: TimeoutErrorSvg, "data-testid": "error-image" }), _jsx(Spacer, { vSpace: 32 }), _jsx(S.Text, __assign({ "data-testid": "error-text" }, { children: t('notAuthorised.text') })), _jsx(Spacer, { vSpace: 64 })] }));
};
export default NotAuthorised;
