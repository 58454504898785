var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import FormTextInput from '../FormTextInput/FormTextInput';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Separator = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  margin: 0 0.5rem;\n  opacity: 0.65;\n"], ["\n  display: inline-block;\n  margin: 0 0.5rem;\n  opacity: 0.65;\n"])));
export var Label = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  letter-spacing: 0.7px;\n"], ["\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  letter-spacing: 0.7px;\n"])));
export var Input = styled(FormTextInput)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  width: 4.3rem;\n\n  input {\n    text-align: center;\n  }\n"], ["\n  display: inline-block;\n  width: 4.3rem;\n\n  input {\n    text-align: center;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
