var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Spacer } from '@bp/ui-components/web';
import * as S from './SpreedlyCardForm.styles';
import { useTheme } from 'styled-components';
import ErrorMessage from '../../Atoms/ErrorMessage/ErrorMessage';
import { useFormContext } from 'react-hook-form';
import useValidation from '../../../utils/hooks/useValidation';
import { CardTypes } from '../../../common/enums';
var SpreedlyCardForm = function (_a) {
    var _b, _c;
    var numberLabel = _a.numberLabel, cvvLabel = _a.cvvLabel;
    var theme = useTheme();
    var errorMessages = useValidation().errorMessages;
    var _d = useFormContext(), formState = _d.formState, setError = _d.setError, register = _d.register, setValue = _d.setValue, clearErrors = _d.clearErrors;
    var _e = useState(), validationResult = _e[0], setValidationResult = _e[1];
    var spreedlyStyling = "background-clip: padding-box;border-radius:0; width: 100%; background-color: ".concat(theme.bpCore.colors.appBackground.primary, "; border: 1px solid ").concat(theme.bpCore.colors.lightGrey, "; height: 3rem; padding: 0 1rem; font-size: 1rem; box-shadow: none; box-sizing: border-box;");
    var spreedlyErrorStyling = "border-color: ".concat(theme.bpCore.colors.error, ";");
    var spreedlyValidStyling = "border-color: ".concat(theme.bpCore.colors.lightGrey, ";");
    var error = (((_b = formState.errors.spreedly_number) === null || _b === void 0 ? void 0 : _b.message) ||
        ((_c = formState.errors.spreedly_cvv) === null || _c === void 0 ? void 0 : _c.message));
    var validateCVVFormat = function (cvvLength, validCvv, cardType) {
        return cvvLength === (cardType === CardTypes.AMEX ? 4 : 3) && validCvv;
    };
    var validateCardType = function (cardType) { return Object.values(CardTypes).includes(cardType); };
    var fieldEventHandler = function (activeElement, event) {
        if (event === 'input') {
            window.Spreedly.validate();
        }
        else if (event === 'blur') {
            setValue("spreedly_".concat(activeElement), '', {
                shouldTouch: true,
                shouldValidate: true,
            });
            window.Spreedly.validate();
        }
    };
    useEffect(function () {
        window.Spreedly.init("".concat(process.env.REACT_APP_SPREEDLY_KEY), {
            numberEl: 'spreedly_number',
            cvvEl: 'spreedly_cvv',
        });
        window.Spreedly.on('ready', function () {
            window.Spreedly.setFieldType('number', 'tel');
            window.Spreedly.setFieldType('cvv', 'tel');
            window.Spreedly.setNumberFormat('prettyFormat');
            window.Spreedly.setStyle('number', spreedlyStyling);
            window.Spreedly.setStyle('cvv', spreedlyStyling);
            register('spreedly_number');
            register('spreedly_cvv');
            window.Spreedly.on('fieldEvent', fieldEventHandler);
            window.Spreedly.on('validation', setValidationResult);
        });
    }, []);
    useEffect(function () {
        if (validationResult) {
            var validNumber = validationResult.validNumber, numberLength = validationResult.numberLength, cvvLength = validationResult.cvvLength, cardType = validationResult.cardType, validCvv = validationResult.validCvv;
            if (validNumber) {
                if (validateCardType(cardType))
                    clearErrors('spreedly_number');
                else
                    setError('spreedly_number', {
                        type: 'accepted',
                        message: errorMessages.spreedly_number_accepted,
                    });
            }
            else if (formState.touchedFields.spreedly_number) {
                setError('spreedly_number', {
                    type: numberLength ? 'pattern' : 'required',
                    message: numberLength ? errorMessages.spreedly_number : errorMessages.required,
                });
            }
            if (validateCVVFormat(cvvLength, validCvv, cardType)) {
                clearErrors('spreedly_cvv');
            }
            else if (formState.touchedFields.spreedly_cvv) {
                setError('spreedly_cvv', {
                    type: cvvLength ? 'pattern' : 'required',
                    message: cvvLength ? errorMessages.spreedly_cvv : errorMessages.required,
                });
            }
        }
    }, [validationResult]);
    useEffect(function () {
        window.Spreedly.setStyle('number', formState.errors.spreedly_number ? spreedlyErrorStyling : spreedlyValidStyling);
        window.Spreedly.setStyle('cvv', formState.errors.spreedly_cvv ? spreedlyErrorStyling : spreedlyValidStyling);
    }, [formState]);
    return (_jsxs(_Fragment, { children: [_jsxs(S.FormRow, { children: [_jsxs(S.CardNumberColumn, __assign({ id: "inputCardNumber", "aria-label": "inputCardNumber" }, { children: [_jsx(S.FormLabel, { children: numberLabel }), _jsx(S.SpreedlyInput, { "data-testid": "spreedly-number-test", id: "spreedly_number" })] })), _jsxs(S.CVVColumn, __assign({ id: "inputCvv", "aria-label": "inputCvv" }, { children: [_jsx(S.FormLabel, { children: cvvLabel }), _jsx(S.SpreedlyInput, { "data-testid": "spreedly-cvv-test", id: "spreedly_cvv" })] }))] }), error && (_jsxs(_Fragment, { children: [_jsx(Spacer, { vSpace: 8 }), _jsx(ErrorMessage, { errorMessage: error })] }))] }));
};
export default SpreedlyCardForm;
