var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './PageTitle.styles';
var PageTitle = function (_a) {
    var titleKey = _a.titleKey, onPressBack = _a.onPressBack;
    var t = useTranslation().t;
    var pathname = useLocation().pathname;
    var _b = useHistory(), search = _b.location.search, historyReplace = _b.replace;
    var titleKeys = {
        '/': 'cardInformation',
        '/billing': 'billingAddress',
        '/contact-information': 'contactInformation',
    };
    var backButtonHandler = function () {
        switch (pathname) {
            case '/billing':
                historyReplace("/".concat(search));
                break;
            case '/contact-information':
                historyReplace("/billing".concat(search));
                break;
        }
    };
    return (_jsx(_Fragment, { children: (titleKey || titleKeys[pathname]) && (_jsxs(S.MenuTitle, { children: [_jsx(S.IconContainer, __assign({ onClick: onPressBack || backButtonHandler, "data-testid": "back-icon", role: "button", "aria-label": t('accessibility.goBack') }, { children: (onPressBack || ['/billing', '/contact-information'].includes(pathname)) && (_jsx(S.ArrowButton, {})) })), _jsx("div", { children: t("header.".concat(titleKey || titleKeys[pathname])) }), _jsx(S.IconContainer, { children: _jsx(S.EmptyTab, {}) })] })) }));
};
export default PageTitle;
