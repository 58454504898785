var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import * as S from './Checkbox.styles';
var Checkbox = function (_a) {
    var name = _a.name, label = _a.label;
    var _b = useController({ name: name }).field, value = _b.value, onChange = _b.onChange;
    var onPressHandler = function () { return onChange(!value); };
    return (_jsxs(S.CheckboxElement, __assign({ id: name, "aria-label": name, "data-testid": name, onClick: onPressHandler, role: "checkbox" }, { children: [_jsx(S.StyledCheckbox, { children: value && (_jsx(S.Icon, __assign({ viewBox: "0 0 24 24" }, { children: _jsx("polyline", { points: "20 6 9 17 4 12" }) }))) }), _jsx(S.Text, { children: label })] })));
};
export default Checkbox;
