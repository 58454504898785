var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var FormRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n"])));
export var FormLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n"], ["\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n"])));
export var CardNumberColumn = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-grow: 1;\n  margin-right: 1rem;\n"], ["\n  flex-grow: 1;\n  margin-right: 1rem;\n"])));
export var CVVColumn = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 5rem;\n"], ["\n  width: 5rem;\n"])));
export var SpreedlyInput = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 3rem;\n  overflow: hidden;\n  margin-top: 0.5rem;\n"], ["\n  height: 3rem;\n  overflow: hidden;\n  margin-top: 0.5rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
