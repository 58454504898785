var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #fafafa;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n"], ["\n  background-color: #fafafa;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n"])));
export var ContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-grow: 1;\n  position: relative;\n  & > * {\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n  }\n"], ["\n  flex-grow: 1;\n  position: relative;\n  & > * {\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n  }\n"])));
export var Iframe = styled.iframe(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  border: 0;\n  width: 100%;\n  height: 100%;\n"], ["\n  margin: 0;\n  padding: 0;\n  border: 0;\n  width: 100%;\n  height: 100%;\n"])));
export var LoadingContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: #fafafa;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  background-color: #fafafa;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var rotate = keyframes(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n"], ["\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n"])));
export var LoadingImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 100px;\n  height: auto;\n  margin: 3.875rem;\n  animation: ", " 2s linear infinite;\n"], ["\n  width: 100%;\n  max-width: 100px;\n  height: auto;\n  margin: 3.875rem;\n  animation: ", " 2s linear infinite;\n"])), rotate);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
