import defaultsDeep from 'lodash.defaultsdeep';
import de_DE from './locales/de-DE.json';
import en_GB from './locales/en-GB.json';
import en_US from './locales/en-US.json';
import es_ES from './locales/es-ES.json';
import fr_FR from './locales/fr-FR.json';
import nl_NL from './locales/nl-NL.json';
import fallback from './messages.json';
export default {
    de_DE: de_DE,
    en_GB: en_GB,
    en_US: en_US,
    es_ES: es_ES,
    fr_FR: fr_FR,
    nl_NL: nl_NL,
};
export var TRANSLATIONS = {
    de_DE: defaultsDeep(de_DE, fallback),
    en_GB: defaultsDeep(en_GB, fallback),
    en_US: defaultsDeep(en_US, fallback),
    nl_NL: defaultsDeep(nl_NL, fallback),
    es_ES: defaultsDeep(es_ES, fallback),
    fr_FR: defaultsDeep(fr_FR, fallback),
};
