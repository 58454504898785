var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var MenuTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 20px;\n  background-color: ", ";\n  font: 1.25rem 'Aral POS';\n  letter-spacing: 1.2px;\n  text-align: center;\n  border-bottom: 1px solid ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 20px;\n  background-color: ", ";\n  font: 1.25rem 'Aral POS';\n  letter-spacing: 1.2px;\n  text-align: center;\n  border-bottom: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.appBackground.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral[400];
});
export var CloseButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:after {\n    /* background-color: red; */\n    display: inline-block;\n    /* content: '\\2715'; */\n    content: '\\2573';\n    color: black;\n    font-size: 17px;\n  }\n"], ["\n  &:after {\n    /* background-color: red; */\n    display: inline-block;\n    /* content: '\\\\2715'; */\n    content: '\\\\2573';\n    color: black;\n    font-size: 17px;\n  }\n"])));
export var ArrowButton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 10px;\n  height: 10px;\n  border: 1px solid black;\n  transform: rotate(45deg);\n  border-top: 0;\n  border-right: 0;\n"], ["\n  width: 10px;\n  height: 10px;\n  border: 1px solid black;\n  transform: rotate(45deg);\n  border-top: 0;\n  border-right: 0;\n"])));
export var IconContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-left: 4px;\n"], ["\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-left: 4px;\n"])));
export var EmptyTab = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n"], ["\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
