var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import FormTextInputCore from '../../Atoms/FormTextInput/FormTextInput';
import PhonePrefixSelectCore from '../../Atoms/PhonePrefixSelect/PhonePrefixSelect';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var InputWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n"], ["\n  display: flex;\n  flex-direction: row;\n"])));
export var PrefixSelect = styled(PhonePrefixSelectCore)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  /* position: relative; */\n  width: 132px;\n"], ["\n  /* position: relative; */\n  width: 132px;\n"])));
export var NumberWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-grow: 1;\n"], ["\n  flex-grow: 1;\n"])));
export var FormTextInput = styled(FormTextInputCore)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  input {\n    margin-top: 0;\n  }\n"], ["\n  width: 100%;\n  input {\n    margin-top: 0;\n  }\n"])));
export var Label = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  letter-spacing: 0.7px;\n  margin-bottom: 0.5rem;\n"], ["\n  display: inline-block;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  letter-spacing: 0.7px;\n  margin-bottom: 0.5rem;\n"])));
export var FormSubLabel = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 0.6875rem;\n  display: inline-block;\n  margin: 0;\n  margin-left: 8px;\n  opacity: 0.8;\n"], ["\n  font-size: 0.6875rem;\n  display: inline-block;\n  margin: 0;\n  margin-left: 8px;\n  opacity: 0.8;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
