var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  letter-spacing: 0.7px;\n"], ["\n  display: inline-block;\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  letter-spacing: 0.7px;\n"])));
export var Dropdown = styled.select(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid\n    ", ";\n  color: ", ";\n  background-color: ", ";\n  border-radius: 0;\n  height: 3rem;\n  width: 100%;\n  box-sizing: border-box;\n\n  margin-top: 0.5rem;\n\n  font-size: 1rem;\n  appearance: none;\n  outline: none;\n  padding: 0 1rem;\n"], ["\n  border: 1px solid\n    ", ";\n  color: ", ";\n  background-color: ", ";\n  border-radius: 0;\n  height: 3rem;\n  width: 100%;\n  box-sizing: border-box;\n\n  margin-top: 0.5rem;\n\n  font-size: 1rem;\n  appearance: none;\n  outline: none;\n  padding: 0 1rem;\n"])), function (_a) {
    var error = _a.error, theme = _a.theme;
    return (error ? theme.bpCore.colors.error : theme.bpCore.colors.neutral[400]);
}, function (_a) {
    var theme = _a.theme, isPlaceholder = _a.isPlaceholder;
    return isPlaceholder ? theme.bpCore.colors.neutral[600] : theme.bpCore.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.appBackground.primary;
});
export var Arrow = styled.i(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: solid black;\n  border-width: 0 1px 1px 0;\n  display: inline-block;\n  padding: 0.25rem;\n  transform: rotate(45deg);\n  -webkit-transform: rotate(45deg);\n  position: absolute;\n  top: 2.875rem;\n  right: 1.25rem;\n"], ["\n  border: solid black;\n  border-width: 0 1px 1px 0;\n  display: inline-block;\n  padding: 0.25rem;\n  transform: rotate(45deg);\n  -webkit-transform: rotate(45deg);\n  position: absolute;\n  top: 2.875rem;\n  right: 1.25rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
