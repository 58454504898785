var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import logo1 from '../../../assets/paymentVisa48.png';
import logo2 from '../../../assets/paymentAmex48.png';
import logo3 from '../../../assets/paymentMastercard48.png';
import { useTranslation } from 'react-i18next';
var CardLogosContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n"])));
var CardLogo = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: auto;\n  height: 56px;\n  margin-right: 0.5rem;\n"], ["\n  width: auto;\n  height: 56px;\n  margin-right: 0.5rem;\n"])));
var DescriptiveText = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  margin: 0;\n"], ["\n  font-size: 0.875rem;\n  margin: 0;\n"])));
var CardLogos = function (_a) {
    var country = _a.country;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(DescriptiveText, { children: t('card.accepted') }), _jsxs(CardLogosContainer, { children: [_jsx(CardLogo, { src: logo1, alt: "Logo Visa" }), country !== 'ES' && _jsx(CardLogo, { src: logo2, alt: "Logo Amex" }), _jsx(CardLogo, { src: logo3, alt: "Logo Mastercard" })] })] }));
};
export default CardLogos;
var templateObject_1, templateObject_2, templateObject_3;
