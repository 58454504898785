var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from './SelectInput.styles';
import { useFormContext } from 'react-hook-form';
import useValidation from '../../../utils/hooks/useValidation';
import { Spacer } from '@bp/ui-components/web';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useEffect } from 'react';
var SelectInput = function (_a) {
    var _b;
    var name = _a.name, label = _a.label, options = _a.options, placeholder = _a.placeholder, _c = _a.required, required = _c === void 0 ? false : _c, onSelect = _a.onSelect;
    var _d = useFormContext(), register = _d.register, formState = _d.formState, watch = _d.watch, trigger = _d.trigger, getValues = _d.getValues, setValue = _d.setValue;
    var errorMessages = useValidation().errorMessages;
    var error = (name && ((_b = formState.errors[name]) === null || _b === void 0 ? void 0 : _b.message));
    var validationRules = {
        required: {
            value: required,
            message: errorMessages.required,
        },
    };
    var registerProps = name
        ? register(name, __assign(__assign({}, validationRules), { onChange: function (e) {
                onSelect === null || onSelect === void 0 ? void 0 : onSelect(e);
                trigger(name);
            } }))
        : {};
    useEffect(function () {
        var defaultValue = getValues(name);
        if (defaultValue)
            setValue(name, defaultValue, { shouldTouch: true, shouldValidate: true });
    }, []);
    return (_jsxs(S.Wrapper, __assign({ id: name, "aria-label": name }, { children: [_jsx(S.Label, { children: label }), _jsxs(S.Dropdown, __assign({}, registerProps, { error: error, isPlaceholder: !watch(name), "data-testid": name, role: "combobox", "aria-label": label || placeholder }, { children: [placeholder && (_jsx("option", __assign({ value: "", disabled: true, hidden: true }, { children: placeholder }))), options === null || options === void 0 ? void 0 : options.map(function (_a) {
                        var value = _a.value, label = _a.label;
                        return (_jsx("option", __assign({ value: value }, { children: label }), value));
                    })] })), _jsx(S.Arrow, {}), error && (_jsxs(_Fragment, { children: [_jsx(Spacer, { vSpace: 8 }), _jsx(ErrorMessage, { errorMessage: error })] }))] })));
};
export default SelectInput;
