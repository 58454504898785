var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ContentArea = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  height: 95vh;\n"], ["\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  height: 95vh;\n"])));
export var ContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 20px;\n"], ["\n  padding: 0 20px;\n"])));
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 1rem;\n  line-height: 1.5;\n  letter-spacing: 0.7px;\n  text-align: left;\n"], ["\n  font-size: 1rem;\n  line-height: 1.5;\n  letter-spacing: 0.7px;\n  text-align: left;\n"])));
export var Heading = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0;\n  font-size: 1rem;\n  line-height: 1.5;\n"], ["\n  margin: 0;\n  font-size: 1rem;\n  line-height: 1.5;\n"])));
export var SmallText = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 0.75rem;\n  line-height: 1.5;\n  margin: 0;\n  letter-spacing: 0.6px;\n  opacity: 0.7;\n"], ["\n  font-size: 0.75rem;\n  line-height: 1.5;\n  margin: 0;\n  letter-spacing: 0.6px;\n  opacity: 0.7;\n"])));
export var ContentMiddleArea = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  border-left: none;\n  border-right: none;\n  background-color: ", ";\n  margin-top: auto;\n  display: flex;\n  flex-direction: column;\n  padding: 16px 20px;\n"], ["\n  border: 1px solid ", ";\n  border-left: none;\n  border-right: none;\n  background-color: ", ";\n  margin-top: auto;\n  display: flex;\n  flex-direction: column;\n  padding: 16px 20px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.appBackground.primary;
});
export var Bottom = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: auto;\n"], ["\n  margin-top: auto;\n"])));
export var Authorisation = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 1px solid ", ";\n  padding: 20px;\n"], ["\n  background-color: ", ";\n  border: 1px solid ", ";\n  padding: 20px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.appBackground.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral[400];
});
export var AuthorisationHeader = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 1rem;\n  line-height: 1.5;\n  letter-spacing: 0.7px;\n"], ["\n  font-size: 1rem;\n  line-height: 1.5;\n  letter-spacing: 0.7px;\n"])));
export var AuthorisationText = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  line-height: 1.5;\n  letter-spacing: 0.7px;\n  color: ", ";\n"], ["\n  font-size: 0.875rem;\n  line-height: 1.5;\n  letter-spacing: 0.7px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral[500];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
