var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './LoadingButton.styles';
import loadingImage from '../../../assets/loadingImage.png';
import { useSettings } from '../../../providers/SettingsProvider';
import { useTranslation } from 'react-i18next';
var Zoom = require('react-awesome-reveal').Zoom;
export var LoadingButton = function (_a) {
    var text = _a.text, alternativeText = _a.alternativeText, onPress = _a.onPress, loading = _a.loading, testID = _a.testID, analyticsEvent = _a.analyticsEvent;
    var onAnalyticsEvent = useSettings().onAnalyticsEvent;
    var t = useTranslation().t;
    var handlePress = function () {
        analyticsEvent && onAnalyticsEvent && onAnalyticsEvent(analyticsEvent);
        onPress && onPress();
    };
    var LoadingButtonViewModel = {
        element: loading ? (_jsxs(_Fragment, { children: [_jsx(Zoom, { children: _jsx(S.Title, { children: alternativeText }) }), _jsx(Zoom, __assign({ style: { height: 30 } }, { children: _jsx(S.Image, { src: loadingImage }) }))] })) : (_jsx(S.Title, { children: text })),
    };
    return (_jsx(S.Button, __assign({ loading: loading, onClick: loading ? undefined : handlePress, "data-testid": testID, role: "button", "aria-label": t('accessibility.loading') }, { children: LoadingButtonViewModel.element })));
};
export default LoadingButton;
