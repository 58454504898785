var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import * as S from './ContactInformation.styles';
import { Spacer } from '@bp/ui-components/web/core';
import FormTextInput from '../../Atoms/FormTextInput/FormTextInput';
import FormPhoneInput from '../../Molecules/FormPhoneInput/FormPhoneInput';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import selectPhonePrefix from '../../../utils/selectPhonePrefix';
import { useLocation } from 'react-router-dom';
import { LoadingButton } from '../../Molecules/DownloadButton/LoadingButton';
import { Slide } from 'react-awesome-reveal';
import TermsAndConditions from '../../Molecules/TermsAndConditions/TermsAndConditions';
import { WalletMicrositeAnalyticsEventContactInfoScreenSaveClick } from '../../../analytics/events/ContactInfoScreenSaveClick';
var ContactInformation = function () {
    var myRefname = useRef(null);
    var t = useTranslation().t;
    var _a = useFormContext(), formState = _a.formState, trigger = _a.trigger;
    var search = useLocation().search;
    var country = new URLSearchParams(search).get('country');
    var authAmount = decodeURIComponent(new URLSearchParams(search).get('authAmount'));
    authAmount = authAmount.length <= 10 ? authAmount : '';
    var onPressButtonHandler = function () { return (formState.isValid ? myRefname.current.click() : trigger()); };
    return (_jsx(Slide, __assign({ triggerOnce: true, direction: "right" }, { children: _jsxs(S.ContentArea, { children: [_jsxs(S.ContentWrapper, { children: [_jsx(Spacer, { vSpace: 32 }), _jsxs(S.Title, { children: [t('contact.contactQuery'), "\u200B\u200B\u200B\u200B\u200B\u200B"] }), _jsx(Spacer, { vSpace: 36 }), _jsx(FormPhoneInput, { name: "phone", label: t('contact.mobileNumber'), defaultPrefix: selectPhonePrefix(country), required: true }), _jsx(Spacer, { vSpace: 20 }), _jsx(FormTextInput, { type: "email", name: "email", label: t('contact.emailAddress'), required: true }), _jsx(Spacer, { vSpace: 20 })] }), _jsxs(S.Bottom, { children: [_jsxs(S.Authorisation, { children: [_jsx(S.AuthorisationHeader, { children: t('card.authHeaderText') }), _jsx(Spacer, { vSpace: 8 }), _jsx(S.AuthorisationText, { children: t('card.authContentText').replace('//amountValue//', authAmount) })] }), _jsx(Spacer, { vSpace: 20 }), _jsxs(S.ContentWrapper, { children: [_jsx(TermsAndConditions, {}), _jsx(Spacer, { vSpace: 24 }), _jsx(LoadingButton, { analyticsEvent: WalletMicrositeAnalyticsEventContactInfoScreenSaveClick({}), loading: formState.isSubmitting || formState.isSubmitted, testID: "ContactInfo-button", text: t('buttons.buttonContact'), alternativeText: t('buttons.buttonSubmitting'), onPress: onPressButtonHandler }), _jsx("button", { type: "submit", ref: myRefname, style: { display: 'none' } }), _jsx(Spacer, { vSpace: 32 })] })] })] }) })));
};
export default ContactInformation;
