var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Spacer, Button } from '@bp/ui-components/web';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ServiceOutageErrorSvg from '../../../assets/time_out_error.svg';
import * as S from './ServiceOutage.styles';
import ContentWrapper from '../../Atoms/ContentWrapper/ContentWrapper';
import { useHistory } from 'react-router-dom';
var PageTitle = function (_a) {
    var heading = _a.heading;
    return _jsx(S.Title, __assign({ "data-testid": "service-outage-title" }, { children: heading }));
};
var ServiceOutageImage = function () {
    return (_jsx(S.Image, { alt: "Service Down", src: ServiceOutageErrorSvg, "data-testid": "service-outage-image" }));
};
var ServiceOutageContentArea = function (_a) {
    var message = _a.message, buttonText = _a.buttonText;
    var search = useHistory().location.search;
    var refreshHandler = function () {
        window.location.assign("/".concat(search));
    };
    return (_jsxs(ContentWrapper, { children: [_jsx(S.Text, __assign({ "data-testid": "service-outage-text" }, { children: message })), _jsx(Spacer, { vSpace: 32 }), _jsx(Button, __assign({ type: "primary", onPress: refreshHandler, testID: "service-outage-button", accessibilityLabel: buttonText }, { children: buttonText }))] }));
};
export var ServiceOutageComponent = function () {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var search = useHistory().location.search;
    var locale = new URLSearchParams(search).get('locale');
    var changeLanguage = function (locale) { return i18n.changeLanguage(locale); };
    useEffect(function () {
        changeLanguage(locale);
    }, [i18n, locale]);
    var viewModel = {
        heading: t('serviceOutage.heading'),
        message: t('serviceOutage.text'),
        buttonText: t('serviceOutage.button'),
    };
    return (_jsxs("div", { children: [_jsx(PageTitle, { heading: viewModel.heading }), _jsx(Spacer, { vSpace: 16 }), _jsx(ServiceOutageImage, {}), _jsx(Spacer, { vSpace: 32 }), _jsx(ServiceOutageContentArea, { message: viewModel.message, buttonText: viewModel.buttonText }), _jsx(Spacer, { vSpace: 64 })] }));
};
