var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as S from './CardInformation.styles';
import { Spacer } from '@bp/ui-components/web';
import { ButtonAction } from '@bp/ui-components/web/core';
import Checkbox from '../../Atoms/Checkbox/Checkbox';
import FormTextInput from '../../Atoms/FormTextInput/FormTextInput';
import ExpirationDate from '../../Atoms/ExpirationDate/ExpirationDate';
import SpreedlyCardForm from '../../Molecules/SpreedlyCardForm/SpreedlyCardForm';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import TermsAndConditions from '../../Molecules/TermsAndConditions/TermsAndConditions';
import AnalyticsButton from '../../Atoms/AnalyticsButton/AnalyticsButton';
import { WalletMicrositeAnalyticsEventCardInfoScreenSaveClick } from '../../../analytics/events/CardInfoScreenSaveClick';
import CardLogos from '../../Molecules/CardLogos/CardLogos';
var CardInformation = function () {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var _b = useHistory(), _c = _b.location, search = _c.search, pathname = _c.pathname, historyReplace = _b.replace, historyListen = _b.listen;
    var _d = useState(pathname === '/'), displayPage = _d[0], setDisplayPage = _d[1];
    var _e = useFormContext(), formState = _e.formState, trigger = _e.trigger, setValue = _e.setValue;
    var locale = new URLSearchParams(search).get('locale');
    var country = new URLSearchParams(search).get('country');
    var displayDefaultCheckbox = new URLSearchParams(search).get('default') !== '1';
    var pageFields = [
        'full_name',
        'expire_month',
        'expire_year',
        'spreedly_cvv',
        'spreedly_number',
    ];
    var isValid = pageFields.every(function (field) {
        return (formState.touchedFields[field] || formState.dirtyFields[field]) && !formState.errors[field];
    });
    useEffect(function () {
        var errorFields = Object.keys(formState.errors).filter(function (field) { return pageFields.includes(field); });
        errorFields.forEach(function (field) { return formState.dirtyFields[field] && !formState.touchedFields[field] && trigger(field); });
    }, [Object.keys(formState.dirtyFields)]);
    var triggerValidation = function () {
        setValue('spreedly_number', '', {
            shouldTouch: true,
            shouldValidate: true,
        });
        setValue('spreedly_cvv', '', {
            shouldTouch: true,
            shouldValidate: true,
        });
        window.Spreedly.validate();
        trigger(pageFields);
    };
    useEffect(function () {
        i18n.changeLanguage(locale);
        historyListen(function (_a) {
            var path = _a.pathname;
            setDisplayPage(path === '/');
            if (path === '/')
                triggerValidation();
        });
    }, []);
    var onPressButtonHandler = function () { return historyReplace("/billing".concat(search)); };
    return (_jsxs(S.ContentArea, __assign({ style: { display: displayPage ? 'flex' : 'none' } }, { children: [_jsx(Spacer, { vSpace: 20 }), _jsx(CardLogos, { country: country }), _jsx(Spacer, { vSpace: 20 }), _jsx(FormTextInput, { label: t('card.name'), optionalLabel: t('optional.appearsOnCard'), name: "full_name", required: true, autoComplete: "cc-name" }), _jsx(Spacer, { vSpace: 24 }), _jsx(SpreedlyCardForm, { numberLabel: t('card.number'), cvvLabel: "CVV" }), _jsx(Spacer, { vSpace: 24 }), _jsx(ExpirationDate, { label: t('card.expiration'), name: "expire", required: true }), _jsx(Spacer, { vSpace: 32 }), displayDefaultCheckbox && _jsx(Checkbox, { label: t('card.default'), name: "defaultPayment" }), _jsxs(S.Bottom, { children: [_jsx(TermsAndConditions, {}), _jsx(Spacer, { vSpace: 24 }), _jsx(AnalyticsButton, __assign({ analyticsEvent: WalletMicrositeAnalyticsEventCardInfoScreenSaveClick({}), type: ButtonAction.PRIMARY, testID: "CardInfo-button", onPress: isValid ? onPressButtonHandler : triggerValidation, accessibilityLabel: t('buttons.buttonCard') }, { children: t('buttons.buttonCard') })), _jsx(Spacer, { vSpace: 32 })] })] })));
};
export default CardInformation;
