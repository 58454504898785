var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { extractUrlQueryParams } from '../utils/urlQueryHelpers';
export var UrlParamContext = createContext({
    defaultCard: '',
    jwt: '',
    country: '',
    paymentUserId: '',
    apiKey: '',
    theme: 'bpCore',
});
export var useUrlParams = function () { return useContext(UrlParamContext); };
var UrlParamProvider = function (_a) {
    var children = _a.children;
    var search = useHistory().location.search;
    var _b = extractUrlQueryParams(search), defaultCard = _b.defaultCard, jwt = _b.jwt, country = _b.country, paymentUserId = _b.anonUserId, apiKey = _b.apiKey, theme = _b.theme;
    return (_jsx(UrlParamContext.Provider, __assign({ value: {
            defaultCard: defaultCard,
            jwt: jwt,
            country: country,
            paymentUserId: paymentUserId,
            apiKey: apiKey,
            theme: theme,
        } }, { children: children })));
};
export default UrlParamProvider;
