import { LanguageLocale, Locale } from '../common/enums';
var shortFormLocaleConverter = function (locale) {
    switch (locale) {
        case LanguageLocale.GERMAN:
        case Locale.de_DE:
            return LanguageLocale.GERMAN;
        case LanguageLocale.NETHERLANDS:
        case Locale.nl_NL:
            return LanguageLocale.NETHERLANDS;
        case LanguageLocale.SPAIN:
        case Locale.es_ES:
            return LanguageLocale.SPAIN;
        case LanguageLocale.US_ENGLISH:
        case Locale.en_US:
            return LanguageLocale.US_ENGLISH;
        default:
            return LanguageLocale.GB_ENGLISH;
    }
};
export default shortFormLocaleConverter;
