var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var CheckboxContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  vertical-align: middle;\n"], ["\n  display: inline-block;\n  vertical-align: middle;\n"])));
export var Icon = styled.svg(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fill: none;\n  stroke: black;\n  stroke-width: 2px;\n"], ["\n  fill: none;\n  stroke: black;\n  stroke-width: 2px;\n"])));
export var CheckboxElement = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n"])));
export var Text = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 0.6875rem;\n  line-height: 1rem;\n  letter-spacing: 0.5px;\n  color: ", ";\n  margin: 0 0 0 1rem;\n"], ["\n  font-size: 0.6875rem;\n  line-height: 1rem;\n  letter-spacing: 0.5px;\n  color: ", ";\n  margin: 0 0 0 1rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.text;
});
export var StyledCheckbox = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  background-color: ", ";\n"], ["\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  background-color: ", ";\n"])), function (_a) {
    var checked = _a.checked, theme = _a.theme;
    return checked ? theme.bpCore.checkbox.borderColor : theme.bpCore.checkbox.checked.borderColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.checkbox.backgroundColor;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
