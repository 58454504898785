var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm, FormProvider as Provider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WalletClient } from '../gateways/graphql/provider';
import { GET_PAYMENT_METHODS, STORE_PAYMENT_TOKEN } from '../gateways/graphql/queries';
import { useQuery, useMutation } from '@apollo/client';
import { SupportedCountries } from '../common/enums';
import FinalModal from '../components/Atoms/FinalModal/FinalModal';
import { useUrlParams } from './UrlParamProvider';
var SPREEDLY_MAX_ATTEMPTS = 5;
var FormProvider = function (_a) {
    var children = _a.children;
    var _b = useHistory(), _c = _b.location, search = _c.search, pathname = _c.pathname, historyReplace = _b.replace, historyListen = _b.listen;
    var t = useTranslation().t;
    var _d = useUrlParams(), defaultCard = _d.defaultCard, jwt = _d.jwt, country = _d.country, paymentUserId = _d.paymentUserId, apiKey = _d.apiKey;
    var _e = useState(''), paymentStatus = _e[0], setPaymentStatus = _e[1];
    var _f = useState(''), lastDigits = _f[0], setLastDigits = _f[1];
    var _g = useState(0), reloadAttempts = _g[0], setReloadAttempts = _g[1];
    var storePaymentToken = useMutation(STORE_PAYMENT_TOKEN, {
        client: WalletClient(jwt, apiKey),
    })[0];
    useQuery(GET_PAYMENT_METHODS, {
        skip: pathname !== '/',
        client: WalletClient(jwt, apiKey),
        variables: {
            userId: paymentUserId,
        },
    });
    var defaultCountry = Object.values(SupportedCountries).includes(country)
        ? country
        : SupportedCountries.UNITED_KINGDOM;
    var formMethods = useForm({
        mode: 'onTouched',
        defaultValues: {
            defaultPayment: defaultCard === '1',
            spreedly_number: '',
            spreedly_cvv: '',
            expire_month: '',
            expire_year: '',
            street_address: '',
            house_number: '',
            postcode: '',
            town: '',
            country: defaultCountry,
        },
    });
    useEffect(function () {
        var fields = [
            'full_name',
            'spreedly_number',
            'spreedly_cvv',
            'expire_month',
            'expire_year',
            'street_address',
            'postcode',
            'town',
            'country',
            'phone_number',
            'email',
        ];
        var hasErrors = Object.keys(formMethods.formState.errors).length;
        var touchedFields = Object.keys(formMethods.formState.touchedFields);
        if (!formMethods.formState.isValid &&
            !hasErrors &&
            fields.every(function (field) { return touchedFields.includes(field); }))
            formMethods.trigger();
    }, [Object.keys(formMethods.formState.touchedFields)]);
    var submitHandler = function (data) {
        var full_name = data.full_name, month = data.expire_month, expire_year = data.expire_year, address1 = data.house_number, address2 = data.street_address, town = data.town, postcode = data.postcode, country = data.country, email = data.email, defaultPayment = data.defaultPayment;
        var phone_number = "".concat(data.phone_prefix).concat(data.phone_number.replace(/\s/g, ''));
        var metadata = { defaultPayment: defaultPayment, paymentUserId: paymentUserId };
        var spreedlyPayload = {
            full_name: full_name,
            month: month,
            year: "20".concat(expire_year),
            address1: address1,
            address2: address2,
            city: town,
            zip: postcode,
            country: country,
            email: email,
            phone_number: phone_number,
            metadata: metadata,
        };
        window.Spreedly.tokenizeCreditCard(spreedlyPayload);
    };
    var serviceOutageHandler = function (err) {
        // TODO: add logger for local development only
        // console.error('Spreedly Error: ', JSON.stringify(err));
        if (reloadAttempts < SPREEDLY_MAX_ATTEMPTS) {
            setReloadAttempts(reloadAttempts + 1);
            window.Spreedly.reload();
        }
        else
            historyReplace("/service-outage".concat(search));
    };
    var spreedlyErrorHandler = function (err) {
        // TODO: add logger for local development only
        // console.error('Spreedly Error: ', JSON.stringify(err));
        setPaymentStatus('failed');
    };
    var continueHandler = function () {
        var _a = formMethods.getValues(), expire_month = _a.expire_month, expire_year = _a.expire_year;
        if (paymentStatus === 'success') {
            window.location.replace("bppaywallet://wallet/threeds/".concat(expire_month, "/").concat(expire_year, "/").concat(lastDigits));
        }
        else if (paymentStatus === 'failed') {
            window.location.replace("bppaywallet://wallet/card-failure/".concat(expire_month, "/").concat(expire_year, "/").concat(lastDigits));
        }
    };
    var paymentMethodHandler = function (token, paymentMethod) {
        var last_four_digits = paymentMethod.last_four_digits, metadata = paymentMethod.metadata;
        setLastDigits(last_four_digits);
        storePaymentToken({
            variables: {
                userId: metadata.paymentUserId,
                token: token,
                isDefault: metadata.defaultPayment,
            },
        })
            .then(function () {
            setPaymentStatus('success');
        })
            .catch(function () {
            setPaymentStatus('failed');
        });
    };
    useEffect(function () {
        window.Spreedly.on('ready', function () {
            window.Spreedly.on('errors', spreedlyErrorHandler);
            window.Spreedly.on('paymentMethod', paymentMethodHandler);
        });
        window.Spreedly.on('consoleError', serviceOutageHandler);
        historyListen(function (_a) {
            var pathname = _a.pathname;
            if (pathname === '/billing')
                formMethods.trigger(['street_address', 'house_number', 'postcode', 'town', 'country']);
        });
    }, []);
    return (_jsxs(Provider, __assign({}, formMethods, { children: [_jsx("form", __assign({ onSubmit: formMethods.handleSubmit(submitHandler) }, { children: children })), paymentStatus && (_jsx(FinalModal, { continueHandler: continueHandler, header: t('finalModal.header'), content: t('finalModal.content'), button: t('finalModal.button') }))] })));
};
export default FormProvider;
