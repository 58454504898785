import jwt from 'jsonwebtoken';
export var getDecodedUserId = function (token) {
    try {
        var userId = jwt.decode(token).anonUserId;
        return userId;
    }
    catch (_a) {
        return '';
    }
};
export var extractUrlQueryParams = function (search) {
    var jwt = new URLSearchParams(search).get('jwt') || '';
    var apiKey = new URLSearchParams(search).get('key') || '';
    var defaultCard = new URLSearchParams(search).get('default') || '0';
    var anonUserId = new URLSearchParams(search).get('userid') || (jwt && getDecodedUserId(jwt)) || '';
    var locale = new URLSearchParams(search).get('locale') || 'de';
    var country = new URLSearchParams(search).get('country') || 'DE';
    var theme = (new URLSearchParams(search).get('theme') || 'bpCore');
    if ((!jwt || !apiKey || !anonUserId) && window.location.pathname !== '/not-authorised')
        window.location.replace("/not-authorised?&locale=".concat(locale));
    return {
        jwt: jwt,
        apiKey: apiKey,
        anonUserId: anonUserId,
        locale: locale,
        country: country,
        defaultCard: defaultCard,
        theme: theme,
    };
};
