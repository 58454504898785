export var LanguageLocale;
(function (LanguageLocale) {
    LanguageLocale["GB_ENGLISH"] = "en";
    LanguageLocale["US_ENGLISH"] = "us";
    LanguageLocale["GERMAN"] = "de";
    LanguageLocale["NETHERLANDS"] = "nl";
    LanguageLocale["SPAIN"] = "es";
})(LanguageLocale || (LanguageLocale = {}));
export var Locale;
(function (Locale) {
    Locale["en_GB"] = "en-GB";
    Locale["de_DE"] = "de-DE";
    Locale["nl_NL"] = "nl-NL";
    Locale["en_US"] = "en-US";
    Locale["es_ES"] = "es-ES";
})(Locale || (Locale = {}));
export var SupportedCountries;
(function (SupportedCountries) {
    SupportedCountries["UNITED_KINGDOM"] = "UK";
    SupportedCountries["GERMANY"] = "DE";
    SupportedCountries["NETHERLANDS"] = "NL";
    SupportedCountries["UNITED_STATES"] = "US";
    SupportedCountries["SPAIN"] = "ES";
})(SupportedCountries || (SupportedCountries = {}));
export var PhonePrefixes;
(function (PhonePrefixes) {
    PhonePrefixes["UK"] = "+44";
    PhonePrefixes["DE"] = "+49";
    PhonePrefixes["NL"] = "+31";
    PhonePrefixes["US"] = "+1";
    PhonePrefixes["ES"] = "+34";
})(PhonePrefixes || (PhonePrefixes = {}));
export var CardTypes;
(function (CardTypes) {
    CardTypes["AMEX"] = "american_express";
    CardTypes["MASTERCARD"] = "master";
    CardTypes["VISA"] = "visa";
})(CardTypes || (CardTypes = {}));
export var States;
(function (States) {
    States["AL"] = "Alabama, AL";
    States["AK"] = "Alaska, AK";
    States["AZ"] = "Arizona, AZ";
    States["AR"] = "Arkansas, AR";
    States["CA"] = "California, CA";
    States["CO"] = "Colorado, CO";
    States["CT"] = "Connecticut, CT";
    States["DE"] = "Delaware, DE";
    States["FL"] = "Florida, FL";
    States["GA"] = "Georgia, GA";
    States["HI"] = "Hawaii, HI";
    States["ID"] = "Idaho, ID";
    States["IL"] = "Illinois, IL";
    States["IN"] = "Indiana, IN";
    States["IA"] = "Iowa, IA";
    States["KS"] = "Kansas, KS";
    States["KY"] = "Kentucky, KY";
    States["LA"] = "Louisiana, LA";
    States["ME"] = "Maine, ME";
    States["MD"] = "Maryland, MD";
    States["MA"] = "Massachusetts, MA";
    States["MI"] = "Michigan, MI";
    States["MN"] = "Minnesota, MN";
    States["MS"] = "Mississippi, MS";
    States["MO"] = "Missouri, MO";
    States["MT"] = "Montana, MT";
    States["NE"] = "Nebraska, NE";
    States["NV"] = "Nevada, NV";
    States["NH"] = "New Hampshire, NH";
    States["NJ"] = "New Jersey, NJ";
    States["NM"] = "New Mexico, NM";
    States["NY"] = "New York, NY";
    States["NC"] = "North Carolina, NC";
    States["ND"] = "North Dakota, ND";
    States["OH"] = "Ohio, OH";
    States["OK"] = "Oklahoma, OK";
    States["OR"] = "Oregon, OR";
    States["PA"] = "Pennsylvania, PA";
    States["RI"] = "Rhode Island, RI";
    States["SC"] = "South Carolina, SC";
    States["SD"] = "South Dakota, SD";
    States["TN"] = "Tennessee, TN";
    States["TX"] = "Texas, TX";
    States["UT"] = "Utah, UT";
    States["VT"] = "Vermont, VT";
    States["VA"] = "Virginia, VA";
    States["WA"] = "Washington, WA";
    States["WV"] = "West Virginia, WV";
    States["WI"] = "Wisconsin, WI";
    States["WY"] = "Wyoming, WY";
})(States || (States = {}));
export var Countries;
(function (Countries) {
    Countries["AF"] = "Afghanistan, AF";
    Countries["AX"] = "Aland Islands, AX";
    Countries["AL"] = "Albania, AL";
    Countries["DZ"] = "Algeria, DZ";
    Countries["AS"] = "American Samoa, AS";
    Countries["AD"] = "Andorra, AD";
    Countries["AO"] = "Angola, AO";
    Countries["AI"] = "Anguilla, AI";
    Countries["AQ"] = "Antarctica, AQ";
    Countries["AG"] = "Antigua And Barbuda, AG";
    Countries["AR"] = "Argentina, AR";
    Countries["AM"] = "Armenia, AM";
    Countries["AW"] = "Aruba, AW";
    Countries["AU"] = "Australia, AU";
    Countries["AT"] = "Austria, AT";
    Countries["AZ"] = "Azerbaijan, AZ";
    Countries["BS"] = "Bahamas, BS";
    Countries["BH"] = "Bahrain, BH";
    Countries["BD"] = "Bangladesh, BD";
    Countries["BB"] = "Barbados, BB";
    Countries["BY"] = "Belarus, BY";
    Countries["BE"] = "Belgium, BE";
    Countries["BZ"] = "Belize, BZ";
    Countries["BJ"] = "Benin, BJ";
    Countries["BM"] = "Bermuda, BM";
    Countries["BT"] = "Bhutan, BT";
    Countries["BO"] = "Bolivia, BO";
    Countries["BA"] = "Bosnia And Herzegovina, BA";
    Countries["BW"] = "Botswana, BW";
    Countries["BV"] = "Bouvet Island, BV";
    Countries["BR"] = "Brazil, BR";
    Countries["IO"] = "British Indian Ocean Territory, IO";
    Countries["BN"] = "Brunei Darussalam, BN";
    Countries["BG"] = "Bulgaria, BG";
    Countries["BF"] = "Burkina Faso, BF";
    Countries["BI"] = "Burundi, BI";
    Countries["KH"] = "Cambodia, KH";
    Countries["CM"] = "Cameroon, CM";
    Countries["CA"] = "Canada, CA";
    Countries["CV"] = "Cape Verde, CV";
    Countries["KY"] = "Cayman Islands, KY";
    Countries["CF"] = "Central African Republic, CF";
    Countries["TD"] = "Chad, TD";
    Countries["CL"] = "Chile, CL";
    Countries["CN"] = "China, CN";
    Countries["CX"] = "Christmas Island, CX";
    Countries["CC"] = "Cocos (Keeling) Islands, CC";
    Countries["CO"] = "Colombia, CO";
    Countries["KM"] = "Comoros, KM";
    Countries["CG"] = "Congo, CG";
    Countries["CD"] = "Congo, Democratic Republic, CD";
    Countries["CK"] = "Cook Islands, CK";
    Countries["CR"] = "Costa Rica, CR";
    Countries["CI"] = "Cote D\"Ivoire, CI";
    Countries["HR"] = "Croatia, HR";
    Countries["CU"] = "Cuba, CU";
    Countries["CY"] = "Cyprus, CY";
    Countries["CZ"] = "Czech Republic, CZ";
    Countries["DK"] = "Denmark, DK";
    Countries["DJ"] = "Djibouti, DJ";
    Countries["DM"] = "Dominica, DM";
    Countries["DO"] = "Dominican Republic, DO";
    Countries["EC"] = "Ecuador, EC";
    Countries["EG"] = "Egypt, EG";
    Countries["SV"] = "El Salvador, SV";
    Countries["GQ"] = "Equatorial Guinea, GQ";
    Countries["ER"] = "Eritrea, ER";
    Countries["EE"] = "Estonia, EE";
    Countries["ET"] = "Ethiopia, ET";
    Countries["FK"] = "Falkland Islands (Malvinas), FK";
    Countries["FO"] = "Faroe Islands, FO";
    Countries["FJ"] = "Fiji, FJ";
    Countries["FI"] = "Finland, FI";
    Countries["FR"] = "France, FR";
    Countries["GF"] = "French Guiana, GF";
    Countries["PF"] = "French Polynesia, PF";
    Countries["TF"] = "French Southern Territories, TF";
    Countries["GA"] = "Gabon, GA";
    Countries["GM"] = "Gambia, GM";
    Countries["GE"] = "Georgia, GE";
    Countries["DE"] = "Germany, DE";
    Countries["GH"] = "Ghana, GH";
    Countries["GI"] = "Gibraltar, GI";
    Countries["GR"] = "Greece, GR";
    Countries["GL"] = "Greenland, GL";
    Countries["GD"] = "Grenada, GD";
    Countries["GP"] = "Guadeloupe, GP";
    Countries["GU"] = "Guam, GU";
    Countries["GT"] = "Guatemala, GT";
    Countries["GG"] = "Guernsey, GG";
    Countries["GN"] = "Guinea, GN";
    Countries["GW"] = "Guinea-Bissau, GW";
    Countries["GY"] = "Guyana, GY";
    Countries["HT"] = "Haiti, HT";
    Countries["HM"] = "Heard Island & Mcdonald Islands, HM";
    Countries["VA"] = "Holy See (Vatican City State), VA";
    Countries["HN"] = "Honduras, HN";
    Countries["HK"] = "Hong Kong, HK";
    Countries["HU"] = "Hungary, HU";
    Countries["IS"] = "Iceland, IS";
    Countries["IN"] = "India, IN";
    Countries["ID"] = "Indonesia, ID";
    Countries["IR"] = "Iran, Islamic Republic Of, IR";
    Countries["IQ"] = "Iraq, IQ";
    Countries["IE"] = "Ireland, IE";
    Countries["IM"] = "Isle Of Man, IM";
    Countries["IL"] = "Israel, IL";
    Countries["IT"] = "Italy, IT";
    Countries["JM"] = "Jamaica, JM";
    Countries["JP"] = "Japan, JP";
    Countries["JE"] = "Jersey, JE";
    Countries["JO"] = "Jordan, JO";
    Countries["KZ"] = "Kazakhstan, KZ";
    Countries["KE"] = "Kenya, KE";
    Countries["KI"] = "Kiribati, KI";
    Countries["KR"] = "Korea, KR";
    Countries["KP"] = "North Korea, KP";
    Countries["KW"] = "Kuwait, KW";
    Countries["KG"] = "Kyrgyzstan, KG";
    Countries["LA"] = "Lao People\"s Democratic Republic, LA";
    Countries["LV"] = "Latvia, LV";
    Countries["LB"] = "Lebanon, LB";
    Countries["LS"] = "Lesotho, LS";
    Countries["LR"] = "Liberia, LR";
    Countries["LY"] = "Libyan Arab Jamahiriya, LY";
    Countries["LI"] = "Liechtenstein, LI";
    Countries["LT"] = "Lithuania, LT";
    Countries["LU"] = "Luxembourg, LU";
    Countries["MO"] = "Macao, MO";
    Countries["MK"] = "Macedonia, MK";
    Countries["MG"] = "Madagascar, MG";
    Countries["MW"] = "Malawi, MW";
    Countries["MY"] = "Malaysia, MY";
    Countries["MV"] = "Maldives, MV";
    Countries["ML"] = "Mali, ML";
    Countries["MT"] = "Malta, MT";
    Countries["MH"] = "Marshall Islands, MH";
    Countries["MQ"] = "Martinique, MQ";
    Countries["MR"] = "Mauritania, MR";
    Countries["MU"] = "Mauritius, MU";
    Countries["YT"] = "Mayotte, YT";
    Countries["MX"] = "Mexico, MX";
    Countries["FM"] = "Micronesia, Federated States Of, FM";
    Countries["MD"] = "Moldova, MD";
    Countries["MC"] = "Monaco, MC";
    Countries["MN"] = "Mongolia, MN";
    Countries["ME"] = "Montenegro, ME";
    Countries["MS"] = "Montserrat, MS";
    Countries["MA"] = "Morocco, MA";
    Countries["MZ"] = "Mozambique, MZ";
    Countries["MM"] = "Myanmar, MM";
    Countries["NA"] = "Namibia, NA";
    Countries["NR"] = "Nauru, NR";
    Countries["NP"] = "Nepal, NP";
    Countries["NL"] = "Netherlands, NL";
    // AN = 'Netherlands Antilles, AN' THE ISLAND WAS DISTABLISHED IN 2010,
    Countries["NC"] = "New Caledonia, NC";
    Countries["NZ"] = "New Zealand, NZ";
    Countries["NI"] = "Nicaragua, NI";
    Countries["NE"] = "Niger, NE";
    Countries["NG"] = "Nigeria, NG";
    Countries["NU"] = "Niue, NU";
    Countries["NF"] = "Norfolk Island, NF";
    Countries["MP"] = "Northern Mariana Islands, MP";
    Countries["NO"] = "Norway, NO";
    Countries["OM"] = "Oman, OM";
    Countries["PK"] = "Pakistan, PK";
    Countries["PW"] = "Palau, PW";
    Countries["PS"] = "Palestinian Territory, Occupied, PS";
    Countries["PA"] = "Panama, PA";
    Countries["PG"] = "Papua New Guinea, PG";
    Countries["PY"] = "Paraguay, PY";
    Countries["PE"] = "Peru, PE";
    Countries["PH"] = "Philippines, PH";
    Countries["PN"] = "Pitcairn, PN";
    Countries["PL"] = "Poland, PL";
    Countries["PT"] = "Portugal, PT";
    Countries["PR"] = "Puerto Rico, PR";
    Countries["QA"] = "Qatar, QA";
    Countries["RE"] = "Reunion, RE";
    Countries["RO"] = "Romania, RO";
    Countries["RU"] = "Russian Federation, RU";
    Countries["RW"] = "Rwanda, RW";
    Countries["BL"] = "Saint Barthelemy, BL";
    Countries["SH"] = "Saint Helena, SH";
    Countries["KN"] = "Saint Kitts And Nevis, KN";
    Countries["LC"] = "Saint Lucia, LC";
    Countries["MF"] = "Saint Martin, MF";
    Countries["PM"] = "Saint Pierre And Miquelon, PM";
    Countries["VC"] = "Saint Vincent And Grenadines, VC";
    Countries["WS"] = "Samoa, WS";
    Countries["SM"] = "San Marino, SM";
    Countries["ST"] = "Sao Tome And Principe, ST";
    Countries["SA"] = "Saudi Arabia, SA";
    Countries["SN"] = "Senegal, SN";
    Countries["RS"] = "Serbia, RS";
    Countries["SC"] = "Seychelles, SC";
    Countries["SL"] = "Sierra Leone, SL";
    Countries["SG"] = "Singapore, SG";
    Countries["SK"] = "Slovakia, SK";
    Countries["SI"] = "Slovenia, SI";
    Countries["SB"] = "Solomon Islands, SB";
    Countries["SO"] = "Somalia, SO";
    Countries["ZA"] = "South Africa, ZA";
    Countries["GS"] = "South Georgia And Sandwich Isl., GS";
    Countries["ES"] = "Spain, ES";
    Countries["LK"] = "Sri Lanka, LK";
    Countries["SD"] = "Sudan, SD";
    Countries["SR"] = "Suriname, SR";
    Countries["SJ"] = "Svalbard And Jan Mayen, SJ";
    Countries["SZ"] = "Swaziland, SZ";
    Countries["SE"] = "Sweden, SE";
    Countries["CH"] = "Switzerland, CH";
    Countries["SY"] = "Syrian Arab Republic, SY";
    Countries["TW"] = "Taiwan, TW";
    Countries["TJ"] = "Tajikistan, TJ";
    Countries["TZ"] = "Tanzania, TZ";
    Countries["TH"] = "Thailand, TH";
    Countries["TL"] = "Timor-Leste, TL";
    Countries["TG"] = "Togo, TG";
    Countries["TK"] = "Tokelau, TK";
    Countries["TO"] = "Tonga, TO";
    Countries["TT"] = "Trinidad And Tobago, TT";
    Countries["TN"] = "Tunisia, TN";
    Countries["TR"] = "Turkey, TR";
    Countries["TM"] = "Turkmenistan, TM";
    Countries["TC"] = "Turks And Caicos Islands, TC";
    Countries["TV"] = "Tuvalu, TV";
    Countries["UG"] = "Uganda, UG";
    Countries["UA"] = "Ukraine, UA";
    Countries["AE"] = "United Arab Emirates, AE";
    Countries["GB"] = "United Kingdom, GB";
    Countries["US"] = "United States, US";
    Countries["UM"] = "United States Outlying Islands, UM";
    Countries["UY"] = "Uruguay, UY";
    Countries["UZ"] = "Uzbekistan, UZ";
    Countries["VU"] = "Vanuatu, VU";
    Countries["VE"] = "Venezuela, VE";
    Countries["VN"] = "Vietnam, VN";
    Countries["VG"] = "Virgin Islands, British, VG";
    Countries["VI"] = "Virgin Islands, U.S., VI";
    Countries["WF"] = "Wallis And Futuna, WF";
    Countries["EH"] = "Western Sahara, EH";
    Countries["YE"] = "Yemen, YE";
    Countries["ZM"] = "Zambia, ZM";
    Countries["ZW"] = "Zimbabw, ZWe";
})(Countries || (Countries = {}));
