import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
var firebaseConfig = {
    apiKey: process.env.REACT_APP_GF_API_KEY,
    authDomain: process.env.REACT_APP_GF_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_GF_PROJECT_ID,
    storageBucket: process.env.REACT_APP_GF_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_GF_MESSAGING_SENDERID,
    appId: process.env.REACT_APP_GF_APP_ID,
    measurementId: process.env.REACT_APP_GF_MEASUREMENT_ID,
};
var app = initializeApp(firebaseConfig);
export var firebaseAnalytics = getAnalytics(app);
