var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var OptionalLabel = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 0.6875rem;\n  display: inline-block;\n  line-height: 1.125rem;\n  letter-spacing: 0.7px;\n  margin: 0;\n  opacity: 0.8;\n"], ["\n  font-size: 0.6875rem;\n  display: inline-block;\n  line-height: 1.125rem;\n  letter-spacing: 0.7px;\n  margin: 0;\n  opacity: 0.8;\n"])));
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var Label = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  letter-spacing: 0.7px;\n  margin-right: 8px;\n"], ["\n  display: inline-block;\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  letter-spacing: 0.7px;\n  margin-right: 8px;\n"])));
export var FormInput = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid\n    ", ";\n  color: ", ";\n  border-radius: 0;\n  height: 3rem;\n  width: 100%;\n  box-sizing: border-box;\n\n  appearance: none;\n  box-shadow: none;\n  outline: none;\n\n  margin-top: 0.5rem;\n  padding: 0 1rem;\n\n  font-size: 1rem;\n  letter-spacing: 0.7px;\n\n  &::placeholder {\n    color: ", ";\n  }\n"], ["\n  border: 1px solid\n    ", ";\n  color: ", ";\n  border-radius: 0;\n  height: 3rem;\n  width: 100%;\n  box-sizing: border-box;\n\n  appearance: none;\n  box-shadow: none;\n  outline: none;\n\n  margin-top: 0.5rem;\n  padding: 0 1rem;\n\n  font-size: 1rem;\n  letter-spacing: 0.7px;\n\n  &::placeholder {\n    color: ", ";\n  }\n"])), function (_a) {
    var error = _a.error, theme = _a.theme;
    return error
        ? theme.bpCore.textInput.error.borderColor
        : theme.bpCore.textInput.default.borderColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.textInput.default.color;
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.colors.neutral['600'];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
