var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './FormTextInput.styles';
import { Spacer } from '@bp/ui-components/web';
import ErrorMessage from '../../Atoms/ErrorMessage/ErrorMessage';
import { useFormContext } from 'react-hook-form';
import useValidation from '../../../utils/hooks/useValidation';
var FormTextInput = function (_a) {
    var _b;
    var name = _a.name, label = _a.label, optionalLabel = _a.optionalLabel, placeholder = _a.placeholder, _c = _a.type, type = _c === void 0 ? 'text' : _c, _d = _a.required, required = _d === void 0 ? false : _d, hideError = _a.hideError, onChange = _a.onChange, onBlur = _a.onBlur, onKeyDown = _a.onKeyDown, min = _a.min, max = _a.max, deps = _a.deps, className = _a.className, autoComplete = _a.autoComplete;
    var _e = useFormContext(), register = _e.register, formState = _e.formState;
    var _f = useValidation(), errorMessages = _f.errorMessages, patterns = _f.patterns, maxLengths = _f.maxLengths;
    var maxLength = (name && maxLengths[name]) || maxLengths.default;
    var validationRules = {
        required: {
            value: required,
            message: errorMessages.required,
        },
        maxLength: {
            value: maxLength,
            message: errorMessages.generic,
        },
        deps: deps,
    };
    if (name && patterns[name]) {
        validationRules.pattern = {
            value: patterns[name],
            message: errorMessages[name] || errorMessages.generic,
        };
    }
    if (min)
        validationRules.min = {
            value: min,
            message: errorMessages.generic,
        };
    if (max)
        validationRules.max = {
            value: max,
            message: errorMessages.generic,
        };
    var error = (name && ((_b = formState.errors[name]) === null || _b === void 0 ? void 0 : _b.message));
    var registerProps = name
        ? register(name, __assign(__assign({}, validationRules), { onChange: onChange, onBlur: onBlur }))
        : {};
    return (_jsxs(S.Container, __assign({ id: name, "aria-label": name, className: className }, { children: [(label || optionalLabel) && (_jsxs(_Fragment, { children: [_jsx(S.Label, { children: label }), _jsx(S.OptionalLabel, { children: optionalLabel })] })), _jsx(S.FormInput, __assign({ "aria-label": label || placeholder, name: name, placeholder: placeholder, type: type, error: error, "data-testid": name, onChange: onChange, onBlur: onBlur, onKeyDown: onKeyDown, maxLength: maxLength, autoComplete: autoComplete }, registerProps)), !hideError && error && (_jsxs(_Fragment, { children: [_jsx(Spacer, { vSpace: 8 }), _jsx(ErrorMessage, { errorMessage: error })] }))] })));
};
export default FormTextInput;
