import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PageTitle from '../../Atoms/PageTitle/PageTitle';
import * as S from './Iframe.styles';
import LoadingSpinner from '../../../assets/loading.svg';
var Iframe = function (_a) {
    var titleKey = _a.titleKey, initialUrl = _a.initialUrl, backHandler = _a.backHandler, sandbox = _a.sandbox;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        document.body.style.overflow = 'hidden';
        return function () {
            document.body.style.overflow = 'scroll';
        };
    }, []);
    var onLoadHandler = function () { return setLoading(false); };
    var onErrorHandler = function () {
        // limitation on safari that window.open is not supported in an async block
        window.location.assign(initialUrl);
        backHandler === null || backHandler === void 0 ? void 0 : backHandler();
    };
    useEffect(function () {
        fetch(initialUrl).catch(onErrorHandler);
    }, []);
    return ReactDOM.createPortal(_jsxs(S.Wrapper, { children: [titleKey && (_jsx(PageTitle, { titleKey: titleKey, onPressBack: backHandler })), _jsxs(S.ContentWrapper, { children: [_jsx(S.Iframe, { src: initialUrl, onLoad: onLoadHandler, onError: onErrorHandler }), loading && (_jsx(S.LoadingContainer, { children: _jsx(S.LoadingImage, { src: LoadingSpinner }) }))] })] }), document.getElementById('iframe-portal'));
};
export default Iframe;
