var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import * as S from './PhonePrefixSelect.styles';
import { DownArrow, UpArrow } from '@bp/ui-components/web';
import { useFormContext } from 'react-hook-form';
import useValidation from '../../../utils/hooks/useValidation';
import countriesData from '../../../assets/flags/flags';
import { useTranslation } from 'react-i18next';
import { FlatList, Modal } from 'react-native';
import * as countryTranslations from 'i18n-iso-countries';
import { Button, ButtonSize, TextInput } from '@bp/ui-components/web/core';
import RadioButtonBlackSelected from '../../../assets/radioButtonBlack/radioButtonBlackSelected.png';
import RadioButtonBlack from '../../../assets/radioButtonBlack/radioButtonBlack.png';
import shortFormLocaleConverter from '../../../utils/shortFormLocaleConverter';
// //@ts-ignore
// import Modal from 'modal-react-native-web';
countryTranslations.registerLocale(require('i18n-iso-countries/langs/en.json'));
countryTranslations.registerLocale(require('i18n-iso-countries/langs/nl.json'));
countryTranslations.registerLocale(require('i18n-iso-countries/langs/de.json'));
countryTranslations.registerLocale(require('i18n-iso-countries/langs/es.json'));
var PhonePrefixSelect = function (_a) {
    var _b;
    var name = _a.name, _c = _a.required, required = _c === void 0 ? false : _c, className = _a.className;
    var _d = useState(false), isMenuOpen = _d[0], setIsMenuOpen = _d[1];
    var _e = useState(''), searchCountryInputValue = _e[0], setSearchCountryInputValue = _e[1];
    var _f = useState(countriesData), countriesList = _f[0], setCountriesList = _f[1];
    var _g = useState(0), initialIndex = _g[0], setInitialIndex = _g[1];
    var _h = useFormContext(), formState = _h.formState, watch = _h.watch, setValue = _h.setValue, register = _h.register;
    var errorMessages = useValidation().errorMessages;
    var _j = useTranslation(), i18n = _j.i18n, t = _j.t;
    var value = watch(name);
    var _k = useState(), country = _k[0], setCountry = _k[1];
    var toggleMenuHandler = function (e) {
        // if (!value && isMenuOpen) setValue(name, value, { shouldTouch: true, shouldValidate: true });
        setIsMenuOpen(true);
    };
    useEffect(function () {
        register(name, {
            required: {
                value: required,
                message: errorMessages.required,
            },
        });
        setCountry(value);
    });
    var icon = (_b = countriesData.find(function (item) { return value === item.prefix; })) === null || _b === void 0 ? void 0 : _b.flagBase64;
    var selectCountry = function (prefix) {
        var index = countriesData.map(function (object) { return object.prefix; }).indexOf(prefix);
        setInitialIndex(index);
        setCountry(prefix);
        setValue(name, prefix, { shouldValidate: true });
    };
    var onInputChange = function (value) {
        setSearchCountryInputValue(value);
        var filteredData = Object.keys(countriesData)
            .filter(function (key) {
            return (
            //@ts-ignore
            countryTranslations
                //@ts-ignore
                .getName(countriesData[key].alpha_2, shortFormLocaleConverter(i18n.language), {
                select: 'official',
            })
                .toLowerCase()
                .includes(value.toLowerCase()));
        })
            //@ts-ignore
            .map(function (foundKey) { return countriesData[foundKey]; });
        setCountriesList(filteredData);
    };
    var onInputClear = function () {
        setSearchCountryInputValue('');
        setCountriesList(countriesData);
    };
    var renderSpecificComponent = function (index, item) {
        var label = countryTranslations.getName(item.alpha_2, shortFormLocaleConverter(i18n.language), {
            select: 'official',
        }) +
            ' ' +
            item.prefix;
        return (_jsxs(S.Country, __assign({ testID: item.prefix, "aria-label": label, isFirstChild: index === 0, onPress: function () {
                selectCountry(item.prefix);
            } }, { children: [_jsx(S.Flag, { src: item.flagBase64 }), _jsx(S.Name, { children: label }), _jsx(S.RadioButton, { src: country === item.prefix ? RadioButtonBlackSelected : RadioButtonBlack })] })));
    };
    return (_jsxs(S.PhonePrefixSelectWrapper, __assign({ onClick: toggleMenuHandler, id: "phone-prefix-select", error: formState.errors[name], "data-testid": "phone-prefix-select", className: className, role: "combobox", "aria-label": t('accessibility.selectCountryCode') }, { children: [_jsx(S.ValueWrapper, __assign({ id: "phone-prefix-select-value", "data-testid": "phone-prefix-select-value" }, { children: value ? (_jsxs(_Fragment, { children: [_jsx(S.Flag, { src: icon }), value] })) : (_jsx(S.Placeholder, { children: "- - -" })) })), isMenuOpen ? _jsx(UpArrow, { width: '', height: '' }) : _jsx(DownArrow, { width: '', height: '' }), _jsx(_Fragment, { children: _jsxs(Modal, __assign({ visible: isMenuOpen }, { children: [_jsx(S.Backdrop, {}), _jsxs(S.ContentWrapper, __assign({ "aria-label": "Phone Prefix Selection Modal", testID: "phone-prefix-select-menu" }, { children: [_jsx(S.HeaderWrapper, { children: _jsx(S.HeaderTitle, { children: t('header.selectPrefix') }) }), _jsx(S.InputWrapper, { children: _jsx(TextInput, { enableClearInput: true, placeholder: t('placeholder.countryPrefix'), accessibilityLabel: t('placeholder.countryPrefix'), accessibilityHint: t('placeholder.countryPrefix'), value: searchCountryInputValue, onChangeText: function (e) { return onInputChange(e); }, onClear: onInputClear, autoFocus: true }) }), _jsx(FlatList, { initialScrollIndex: countriesList.length === countriesData.length ? initialIndex : null, data: countriesList, renderItem: function (_a) {
                                        var item = _a.item, index = _a.index;
                                        return renderSpecificComponent(index, item);
                                    }, keyExtractor: function (item) { return item.prefix; } }), _jsx(S.ConfirmButtonContainer, { children: _jsx(Button, __assign({ onPress: function () {
                                            onInputClear();
                                            setIsMenuOpen(false);
                                        }, testID: "confirm-phone-prefix", size: ButtonSize.DEFAULT, accessibilityLabel: t('buttons.saveSelection') }, { children: t('buttons.saveSelection') })) })] }))] })) })] })));
};
export default PhonePrefixSelect;
