var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './ExpirationDate.styles';
import { Spacer } from '@bp/ui-components/web';
import ErrorMessage from '../../Atoms/ErrorMessage/ErrorMessage';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
var ExpirationDate = function (_a) {
    var _b, _c;
    var name = _a.name, label = _a.label, _d = _a.required, required = _d === void 0 ? false : _d, className = _a.className;
    var _e = useFormContext(), formState = _e.formState, trigger = _e.trigger, watch = _e.watch, setValue = _e.setValue, setFocus = _e.setFocus;
    var monthName = "".concat(name, "_month");
    var yearName = "".concat(name, "_year");
    var watchYear = watch(yearName);
    var error = (((_b = formState.errors[monthName]) === null || _b === void 0 ? void 0 : _b.message) ||
        ((_c = formState.errors[yearName]) === null || _c === void 0 ? void 0 : _c.message));
    var minYear = new Date().getFullYear() % 1e2; // last two digits of current year
    var minMonth = (Number(watchYear) === minYear ? new Date().getMonth() : 0) + 1; // minMonth equls to current month if expiration year = current year, otherwise is 1
    var changeHandler = function (e) {
        if (e.target.name === monthName) {
            // trigger validation for the other field only if is touched
            if (formState.touchedFields[yearName] || formState.dirtyFields[yearName])
                trigger(yearName);
            // focus automatically the year input
            var value = Number(e.target.value);
            if (value >= minMonth && value <= 12 && value >= 2)
                setFocus(yearName);
        }
    };
    useEffect(function () {
        if (formState.touchedFields[monthName] || formState.dirtyFields[monthName])
            trigger(monthName);
    }, [minMonth]);
    // format month on blur
    var monthBlurHandler = function (e) {
        if (e.target.value && Number(e.target.value) < 10 && Number(e.target.value) >= 0)
            setValue(monthName, "0".concat(Number(e.target.value) || 1));
    };
    // focus month on pressing backspace
    var yearKeyDownHandler = function (e) {
        if (e.key === 'Backspace' && !e.target.selectionStart)
            setFocus(monthName);
    };
    return (_jsxs(S.Container, __assign({ className: className }, { children: [_jsx(S.Label, { children: label }), _jsx(S.Input, { autoComplete: "cc-month", placeholder: "MM", type: "tel", name: monthName, required: required, hideError: true, min: minMonth, max: 12, onChange: changeHandler, onBlur: monthBlurHandler }), _jsx(S.Separator, { children: "/" }), _jsx(S.Input, { autoComplete: "cc-year", placeholder: "YY", type: "tel", name: yearName, required: required, hideError: true, min: minYear, onChange: changeHandler, onKeyDown: yearKeyDownHandler }), error && (_jsxs(_Fragment, { children: [_jsx(Spacer, { vSpace: 8 }), _jsx(ErrorMessage, { errorMessage: error })] }))] })));
};
export default ExpirationDate;
