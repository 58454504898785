import { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import PageTitle from '../components/Atoms/PageTitle/PageTitle';
import Billing from '../components/Organisms/Billing/Billing';
import NotAuthorised from '../components/Pages/NotAuthorised/NotAuthorised';
import CardInformation from '../components/Organisms/CardInformation/CardInformation';
import ContactInformation from '../components/Organisms/ContactInformation/ContactInformation';
import ServiceOutage from '../components/Pages/ServiceOutage/ServiceOutage';

const Routes = () => {
  const {
    location: { pathname, search },
    listen: historyListen,
  } = useHistory();

  useEffect(() => {
    historyListen(() => {
      window.scrollTo(0, 0);
    });

    // resize is beeing triggered when we have an interaction with the keyboard.
    window.addEventListener('resize', function () {
      let metaViewport;
      metaViewport = document.querySelector('meta[name=viewport]');
      if (document.activeElement.prop('type') === 'text') {
        // Logic for while keyboard is shown
        let initialHeight = window.innerHeight;
        metaViewport.setAttribute(
          'content',
          'height=' + initialHeight + 'px, width=device-width, initial-scale=1.0',
        );
      }
    });

    if (!['/', '/not-authorised', '/service-outage'].includes(pathname))
      window.location.replace(`/${search}`);
  }, []);

  return (
    <>
      <PageTitle />
      <CardInformation />
      <Switch>
        <Route exact path="/billing" component={Billing} />
        <Route exact path="/contact-information" component={ContactInformation} />
        <Route exact path="/not-authorised" component={NotAuthorised} />
        <Route exact path="/service-outage" component={ServiceOutage} />
      </Switch>
    </>
  );
};

export default Routes;
