import { jsx as _jsx } from "react/jsx-runtime";
import { BrowserRouter } from 'react-router-dom';
import { AppWrapper } from './components/Templates';
import StyleWrapper from './StyleWrapper';
import Routes from './router/router';
import FormProvider from './providers/FormProvider';
import { SettingsProvider } from './providers/SettingsProvider';
import UrlParamProvider from './providers/UrlParamProvider';
var App = function () {
    return (_jsx(BrowserRouter, { children: _jsx(UrlParamProvider, { children: _jsx(StyleWrapper, { children: _jsx(SettingsProvider, { children: _jsx(FormProvider, { children: _jsx(AppWrapper, { children: _jsx(Routes, {}) }) }) }) }) }) }));
};
export default App;
