var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  width: 100%;\n"], ["\n  ", "\n  width: 100%;\n"])), function (_a) {
    var bgColor = _a.bgColor;
    return (bgColor ? "background:  ".concat(bgColor, ";") : '');
});
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  max-width: ", ";\n  margin: 0 auto;\n  padding: ", " 1rem;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  position: relative;\n"], ["\n  width: 100%;\n  max-width: ", ";\n  margin: 0 auto;\n  padding: ", " 1rem;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  position: relative;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.minWidth;
}, function (_a) {
    var _b = _a.vPadding, vPadding = _b === void 0 ? 0 : _b;
    return vPadding;
});
var templateObject_1, templateObject_2;
