var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as S from '../Billing.styles';
import SelectInput from '../../../Atoms/SelectInput/SelectInput';
import FormTextInput from '../../../Atoms/FormTextInput/FormTextInput';
import { Spacer, ButtonAction } from '@bp/ui-components/web/core';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Countries, States, LanguageLocale, Locale } from '../../../../common/enums';
import { useTranslation } from 'react-i18next';
import AnalyticsButton from '../../../Atoms/AnalyticsButton/AnalyticsButton';
import { WalletMicrositeAnalyticsEventBillingAddressScreenSaveClick } from '../../../../analytics/events/BillingAddressScreenSaveClick';
var BillingUS = function () {
    var t = useTranslation().t;
    var _a = useFormContext(), formState = _a.formState, trigger = _a.trigger;
    var _b = useHistory(), search = _b.location.search, historyReplace = _b.replace;
    var pageRequiredFields = ['street_address', 'zipcode', 'city'];
    var pageOptionalFields = ['house_number'];
    var pageFields = pageRequiredFields.concat(pageOptionalFields);
    var locale = new URLSearchParams(search).get('locale');
    var countries = Object.keys(Countries).map(function (value) { return ({
        label: Countries[value],
        value: value,
    }); });
    var states = Object.keys(States).map(function (value) { return ({
        label: States[value],
        value: value,
    }); });
    var onPressButtonHandler = function () {
        pageRequiredFields.every(function (field) { return formState.dirtyFields[field] || formState.touchedFields[field]; }) && pageFields.every(function (field) { return !formState.errors[field]; })
            ? historyReplace("/contact-information".concat(search))
            : trigger(pageFields);
    };
    useEffect(function () {
        var validatedFields = pageFields.filter(function (field) { return formState.touchedFields[field] || formState.dirtyFields[field]; });
        trigger(validatedFields);
    }, []);
    useEffect(function () {
        var errorFields = Object.keys(formState.errors).filter(function (field) { return pageFields.includes(field); });
        errorFields.forEach(function (field) { return formState.dirtyFields[field] && !formState.touchedFields[field] && trigger(field); });
    }, [Object.keys(formState.dirtyFields)]);
    return (_jsx(S.SlideWrapper, __assign({ triggerOnce: true, direction: "right" }, { children: _jsxs(S.ContentArea, { children: [_jsx(Spacer, { vSpace: 32 }), _jsx(S.Title, { children: t('address.addressQuery') }), _jsx(Spacer, { vSpace: 36 }), _jsx(FormTextInput, { label: t('address.streetName'), name: "street_address", required: true }), _jsx(Spacer, { vSpace: 20 }), _jsx(FormTextInput, { label: t('address.propertyNumber'), name: "house_number", optionalLabel: locale === (LanguageLocale.GERMAN || Locale.de_DE) ? '' : t('optional.optional') }), _jsx(Spacer, { vSpace: 20 }), _jsx(FormTextInput, { label: t('address.town'), name: "city", required: true }), _jsx(Spacer, { vSpace: 20 }), _jsx(SelectInput, { name: "state", label: t('address.state'), options: states, placeholder: t('address.statePlaceholder'), required: true }), _jsx(Spacer, { vSpace: 20 }), _jsx(FormTextInput, { label: t('address.postalCode'), name: "zipcode", required: true }), _jsx(Spacer, { vSpace: 20 }), _jsx(SelectInput, { name: "country", label: t('address.country'), options: countries, placeholder: t('address.countryPlaceholder'), required: true }), _jsx(Spacer, { vSpace: 50 }), _jsxs(S.ButtonSection, { children: [_jsx(AnalyticsButton, __assign({ analyticsEvent: WalletMicrositeAnalyticsEventBillingAddressScreenSaveClick({}), type: ButtonAction.PRIMARY, testID: "Billinginfo-button", onPress: onPressButtonHandler, accessibilityLabel: t('buttons.buttonAddress') }, { children: t('buttons.buttonAddress') })), _jsx(Spacer, { vSpace: 32 })] })] }) })));
};
export default BillingUS;
