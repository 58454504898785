var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
var rotateAnimation = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%    { transform: rotate(0deg);  }\n  100%  { transform: rotate(360deg);  }\n"], ["\n  0%    { transform: rotate(0deg);  }\n  100%  { transform: rotate(360deg);  }\n"])));
export var Button = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 46px;\n  height: 1.6vh;\n  opacity: ", ";\n  cursor: pointer;\n  width: 100%;\n  border-radius: 50px;\n  background-color: ", ";\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: center;\n  align-items: center;\n"], ["\n  min-height: 46px;\n  height: 1.6vh;\n  opacity: ", ";\n  cursor: pointer;\n  width: 100%;\n  border-radius: 50px;\n  background-color: ", ";\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: center;\n  align-items: center;\n"])), function (_a) {
    var loading = _a.loading;
    return (loading ? 0.5 : 1);
}, function (_a) {
    var theme = _a.theme;
    return theme.bpCore.button.type.primary.backgroundColor;
});
export var Title = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 0.95rem;\n  color: white;\n"], ["\n  font-size: 0.95rem;\n  color: white;\n"])));
export var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 30px;\n  width: 30px;\n  margin-right: 20px;\n  animation: ", " 1.5s linear infinite;\n"], ["\n  height: 30px;\n  width: 30px;\n  margin-right: 20px;\n  animation: ", " 1.5s linear infinite;\n"])), rotateAnimation);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
