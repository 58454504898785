var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as S from './FormPhoneInput.styles';
import { Spacer } from '@bp/ui-components/web';
import ErrorMessage from '../../Atoms/ErrorMessage/ErrorMessage';
import { useFormContext } from 'react-hook-form';
import countriesData from '../../../assets/flags/flags';
var FormPhoneInput = function (_a) {
    var _b, _c;
    var name = _a.name, label = _a.label, optionalLabel = _a.optionalLabel, required = _a.required, className = _a.className, defaultPrefix = _a.defaultPrefix;
    var _d = useFormContext(), watch = _d.watch, setValue = _d.setValue, formState = _d.formState, clearErrors = _d.clearErrors;
    var selectName = "".concat(name, "_prefix");
    var inputName = "".concat(name, "_number");
    var inputWatch = watch(inputName);
    var error = (((_b = formState.errors[selectName]) === null || _b === void 0 ? void 0 : _b.message) ||
        ((_c = formState.errors[inputName]) === null || _c === void 0 ? void 0 : _c.message));
    useEffect(function () {
        setValue(selectName, defaultPrefix, { shouldValidate: true });
    }, []);
    useEffect(function () {
        var _a;
        if (inputWatch) {
            var startsWithSpaces = /^\s+/g;
            // sanitize input if it starts with spaces
            if (inputWatch.match(startsWithSpaces)) {
                setValue(inputName, inputWatch.replace(startsWithSpaces, ''), {
                    shouldDirty: false,
                    shouldTouch: false,
                    shouldValidate: false,
                });
                clearErrors(inputName);
            }
            else {
                // get and set prefix & remove prefix from input
                var prefixValue = (_a = countriesData.find(function (p) { return inputWatch.startsWith(p.prefix); })) === null || _a === void 0 ? void 0 : _a.prefix;
                if (prefixValue) {
                    setValue(selectName, prefixValue, { shouldTouch: true, shouldValidate: true });
                    var number = inputWatch.split(prefixValue)[1];
                    setValue(inputName, number, { shouldTouch: true });
                }
            }
        }
    }, [inputWatch]);
    return (_jsxs(S.Container, __assign({ className: className }, { children: [(label || optionalLabel) && (_jsxs(S.Label, { children: [label, _jsx(S.FormSubLabel, { children: optionalLabel })] })), _jsxs(S.InputWrapper, { children: [_jsx(S.PrefixSelect, { name: selectName, required: required }), _jsx(S.FormTextInput, { type: "tel", name: inputName, required: required, hideError: true })] }), error && (_jsxs(_Fragment, { children: [_jsx(Spacer, { vSpace: 8 }), _jsx(ErrorMessage, { errorMessage: error })] }))] })));
};
export default FormPhoneInput;
