import { SupportedCountries, PhonePrefixes } from '../common/enums';
var selectPhonePrefix = function (country) {
    switch (country) {
        case SupportedCountries.GERMANY:
            return PhonePrefixes.DE;
        case SupportedCountries.NETHERLANDS:
            return PhonePrefixes.NL;
        case SupportedCountries.SPAIN:
            return PhonePrefixes.ES;
        case SupportedCountries.UNITED_STATES:
            return PhonePrefixes.US;
        default:
            return PhonePrefixes.UK;
    }
};
export default selectPhonePrefix;
