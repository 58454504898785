var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './FinalModal.styles';
var FinalModal = function (_a) {
    var continueHandler = _a.continueHandler, header = _a.header, content = _a.content, button = _a.button;
    return (_jsx(S.Wrapper, { children: _jsxs(S.Modal, { children: [_jsx(S.Heading, { children: header }), _jsx(S.Content, { children: content }), _jsx(S.Button, __assign({ onClick: function () { return continueHandler(); }, "data-testid": "finalmodal-button", role: "button", "aria-label": button }, { children: button }))] }) }));
};
export default FinalModal;
